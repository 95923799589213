export const AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS';

export const SUBSCRIPTION = 'SUBSCRIPTION';
export const SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE';
export const SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE';
export const SUBSCRIPTION_DELETE = 'SUBSCRIPTION_DELETE';
export const SUBSCRIPTION_CHAT = 'SUBSCRIPTION_CHAT';
export const INITIAL_QUERY = 'INITIAL_QUERY';
export const ADDITIONAL_QUERY = 'ADDITIONAL_QUERY';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const UPDATE_CAPTION = 'UPDATE_CAPTION';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_FORMTYPE = 'UPDATE_FORMTYPE';
export const UPDATE_TTM_CHECKOUT = 'UPDATE_TTM_CHECKOUT';
export const UPDATE_PLANTASKDETIAL = 'UPDATE_PLANTASKDETIAL';
export const UPDATE_PLANTASKENDTIME = 'UPDATE_PLANTASKENDTIME';
export const UPDATE_PLANTASKPRIORITY = 'UPDATE_PLANTASKPRIORITY';
export const UPDATE_PLANTASKSTARTTIME = 'UPDATE_PLANTASKSTARTTIME';
export const UPDATE_RESULTTASKDETIAL = 'UPDATE_RESULTTASKDETIAL';
export const UPDATE_RESULTTASKENDTIME = 'UPDATE_RESULTTASKENDTIME';
export const UPDATE_RESULTTASKSTARTTIME = 'UPDATE_RESULTTASKSTARTTIME';
export const UPDATE_RESULTTASKPRIORITY = 'UPDATE_RESULTTASKPRIORITY';
export const UPDATE_TASKPROGRESS = 'UPDATE_TASKPROGRESS';

export const USER_DATA_ICON_IMAGE = 'iconImage';
export const USER_DATA_USER_ROLE = 'userRole';
export const USER_DATA_FIRST_NAME = 'firstName';
export const USER_DATA_MIDDLE_NAME = 'middleName';
export const USER_DATA_LAST_NAME = 'lastName';
export const USER_DATA_FIRST_NAME_KANA = 'firstNameKana';
export const USER_DATA_MIDDLE_NAME_KANA = 'middleNameKana';
export const USER_DATA_LAST_NAME_KANA = 'lastNameKana';
export const USER_DATA_BIRTH_DATE = 'birthDate';
export const USER_DATA_PREF_CODE = 'prefCode';
export const USER_DATA_PREF_NAME = 'prefName';
export const USER_DATA_CWTV_NAME = 'cwtvName';
export const USER_DATA_EMAIL_ADDRESS = 'eMailAddress';
export const USER_DATA_STREET_ADDRESS = 'streetAddress';
export const USER_DATA_APARTMENT_NAME = 'apartmentName';
export const USER_DATA_UNIVERSITY_NAME = 'universityName';
export const USER_DATA_COURSE_NAME = 'courseName';
export const USER_DATA_DEPARTMENT_NAME = 'departmentName';
export const USER_DATA_WEEKDAY_PHONE_NUMBER = 'weekdayPhoneNumber';
export const USER_DATA_EMERGENCY_PHONE_NUMBER = 'emergencyPhoneNumber';
export const USER_DATA_EMERGENCY_PERSON_NAME = 'emergencyPersonName';
export const USER_DATA_EMERGENCY_PERSON_ADDRESS = 'emergencyPersonAddress';
export const USER_DATA_BANK_NAME = 'bankName';
export const USER_DATA_BANK_BRANCHE_NAME = 'bankBrancheName';
export const USER_DATA_BANK_ACCOUNT_TYPE = 'bankAccountType';
export const USER_DATA_BANK_ACCOUNT_NUMBER = 'bankAccountNumber';
export const USER_DATA_BANK_ACCOUNT_NAME = 'bankAccountName';
export const USER_DATA_USER_NAME = 'userName';
export const USER_DATA_GRADUATION_YEAR = 'graduationYear';
export const USER_DATA_GRADUATE_SCHOOL_NAME = 'graduateSchoolName';
export const USER_DATA_GRADUATE_SCHOOL_DEPARTMENT_NAME = 'graduateSchoolDepartmentName';
export const USER_DATA_GRADUATE_SCHOOL_COURSE_NAME = 'graduateSchoolCourseName';
export const USER_DATA_GRADUATE_SCHOOL_GRADUATION_YEAR = 'graduateSchoolGraduationYear';
export const USER_DATA_HIGH_SCHOOL_NAME = 'highSchoolName';
export const USER_DATA_DISPLAY_NAME = 'displayName';
export const USER_DATA_USER_REMARKS = 'userRemarks';
export const USER_DATA_EMERGENCY_PERSON_TYPE = 'emergencyPersonType';

export const TASK_DATA_PLANTASKDETIAL = 'planTaskDetial';
export const TASK_DATA_PLANTASKENDTIME = 'planTaskEndTime';
export const TASK_DATA_PLANTASKSTARTTIME = 'planTaskStartTime';
export const TASK_DATA_PLANTASKPRIORITY = 'planTaskPriority';
export const TASK_DATA_RESULTTASKDETIAL = 'resultTaskDetial';
export const TASK_DATA_RESULTTASKENDTIME = 'resultTaskEndTime';
export const TASK_DATA_RESULTTASKSTARTTIME = 'resultTaskStartTime';
export const TASK_DATA_RESULTTASKPRIORITY = 'resultTaskPriority';
export const TASK_DATA_RESUTASKPROGRESS = 'resutaskProgress';
export const TASK_DATA_TASKPROGRESS = 'taskProgress';

export const TTM_DATA_ACHIEVEMENTSCORE = 'achievementScore';
export const TTM_DATA_ACTIONTOIMPROVE = 'ttmActionToImprove';
export const TTM_DATA_EFFORTTARGETS = 'ttmEffortTargets';
export const TTM_DATA_ISSUESTOIMPROVE = 'ttmIssuesToImprove';
export const TTM_DATA_REFLECTIONONDAY = 'ttmReflectionOnDay';
export const TTM_DATA_TASKTIMEMANAGEMENTOUTPUT = 'tasktimeManagementOutput';
export const TTM_DATA_CHECKIN = 'checkIn';
export const TTM_DATA_CHECKOUT = 'checkOut';

export const EVENT_DETAIL_CAPTION = 'caption';
export const EVEMT_DETAIL_ANSWER = 'answer';

export const ERR_NAME_FIRSTNAMEKANAUNMATCH = 'firstNameKanaUnmatch';
export const ERR_NAME_LASTNAMEKANAUNMATCH = 'lastNameKanaUnmatch';
export const ERR_NAME_BANKACCOUNTNAMEUNMATCH = 'bankAccountNameUnmatch';
export const ERR_NAME_MATCHNUMBER = 'matchNumber';

export const MESSAGE_SAVED = '保存しました。';
export const MESSAGE_ICON_SAVED = 'アイコンを保存しました。';
export const MESSAGE_SAVE_FAILED = '保存できません。不正な登録情報があります。';

export const FORMAT_STRING_MMONTH_DDAY = 'M月d日';
export const FORMAT_STRING_AWSDATE = 'YYYY-MM-DD';
export const FORMAT_STRING_YYYYMMDDHHMM = 'YYYY/MM/DD hh:mm';
export const FORMAT_STRING_MMDDHHMM = 'MM/DD hh:mm';
export const FORMAT_STRING_TZDATESTRING = 'YYYY-MM-DDThh:mm:ss.000Z';

export const USER_ROLE = {
    SuperAdministrator:0,
    RegistingUser:11,
    Trainee:17,
    Coach:20,
    CustomerSuccess:7,
};

export const USER_TYPE = {
    TRAINEE:'TRAINEE',
    COACH:'COACH',
    STAFF:'STAFF',
    TEST_USER:'TEST_USER',
    ADMIN:'ADMIN',
};

export const  CustomerChatroomTypes = {
    CUSTOMER_SUCCESS:'CUSTOMER_SUCCESS',
    OPERATIONS:'OPERATIONS',
    PAYMENTS:'PAYMENTS',
    APP_SUPPORT:'APP_SUPPORT',
    TODAY_WORDS:'TODAY_WORDS',
    COCHING_CHAT:'COCHING_CHAT',
};

export const EVENT_TYPE = {
    Intern:{
        Value: 0,
        Label: 'インターン',
        StatusMarks:[
            {
                value:0,
                label:'辞退',
            },
            {
                value:33,
                label:'未定',
            },
            {
                value:66,
                label:'実施中',
            },
            {
                value:100,
                label:'期間終了',
            },
        ]
    },
    EntrySheet:{
        Value: 1,
        Label: 'ES',
        StatusMarks:[
            {
                value:0,
                label:'不合格',
            },
            {
                value:33,
                label:'未定',
            },
            {
                value:66,
                label:'連絡待ち',
            },
            {
                value:100,
                label:'合格',
            },
        ]
    },
    Interview:{
        Value: 2,
        Label: '面接',
        StatusMarks:[
            {
                value:0,
                label:'辞退',
            },
            {
                value:33,
                label:'未定',
            },
            {
                value:66,
                label:'実施中',
            },
            {
                value:100,
                label:'期間終了',
            },
        ]
    },
    UnofficialOffer:{
        Value: 60,
        Label: '内定式',
        StatusMarks:[
            {
                value:0,
                label:'辞退',
            },
            {
                value:33,
                label:'未定',
            },
            {
                value:66,
                label:'連絡済',
            },
            {
                value:100,
                label:'出席',
            },
        ]
    },
};

export const CS_CHATROOM_TAGS = ['23卒','24卒','25卒','26卒','要対応','集団','旧'];

export const URL_PATH_SCHEDULE = '/schedule';
export const URL_PATH_TASKTIMES = '/tasktimes';
export const URL_PATH_TASKTIMES_CREATE = '/tasktimes_create';
export const URL_PATH_CHATROOMS = '/chatrooms';
export const URL_PATH_CHATROOMS_T= '/chatrooms_t';
export const URL_PATH_SUBJECTS = '/subjects';
export const URL_PATH_NOTIFICATIONS = '/notifications';
export const URL_PATH_MYPAGE = '/mypage';
export const URL_PATH_COACHING_MANAGEMENT = '/coach_manage';
export const URL_PATH_TRAINEE_LIST = '/trainee_list';
export const URL_PATH_USERS_MANUAL = '/users_manual';
export const URL_PATH_REGISTRATION = '/regist';
export const URL_PATH_MASTER_MANAGEMENT = '/mmanage';
export const URL_PATH_TODAYS_WORDS_MASTER_MANAGEMENT = '/tw_mmanage';
export const URL_PATH_CORPORATION_MASTER_MANAGEMENT = '/cp_mmanage';
export const URL_PATH_TRAINEE_MASTER_MANAGEMENT = '/tr_mmanage';
export const URL_PATH_COACH_MASTER_MANAGEMENT = '/co_mmanage';
export const URL_PATH_CS_CHAT_SUPPORT = '/chat_support';
export const URL_PATH_CS_CHAT_SUPPORT_COACH = '/c_chat_support';
export const URL_PATH_CS_CHAT_OPERATION = '/chat_operation';
export const URL_PATH_CS_CHAT_OPERATION_COACH = '/c_chat_operation';
export const URL_PATH_CS_CHAT_PAYMENT = '/chat_payment';
export const URL_PATH_CS_CHAT_APP_SUPPORT = '/chat_app_sup';
export const URL_PATH_CS_CHAT_APP_SUPPORT_COACH = '/c_chat_app_sup';
export const URL_PATH_CS_CHAT_COACHING = '/coaching_chat';
export const URL_PATH_CS_TRAINEE_MANAGEMENT = '/trainee_management';
export const URL_PATH_CS_COACH_MANAGEMENT = '/coach_management';
export const URL_PATH_CS_EVENT_BOOKING_STATUS = '/event_booking';
export const URL_PATH_CS_EVENT_TODAY_WORDS_HISTORY = '/today_words';
export const URL_PATH_CS_CONNECT_MANAGEMENT = '/connect_management';
export const URL_PATH_CS_MODULE_TEST_1 = '/test_module_1';
export const URL_PATH_MODULE_TEST_2 = '/test_module_2';
export const URL_PATH_MODULE_TEST_3 = '/test_module_3';
export const URL_PARTS_SLASH = '/';
const URL_PARTS_DETAIL = '/detail';
const URL_PARTS_READ = '/read';
export const URL_PARTS_IEEDITOR = '/ie_editor';
export const URL_PARTS_ESEDITOR = '/es_editor';
const URL_PARTS_TRAINEE_RULES = '/trainee_rules';
const URL_PARTS_MY_ES_LIST = '/my_eslist';
const URL_PARTS_PROFILE_EDIT = '/profile_edit';
const URL_PARTS_ABUILD_BIBLE = '/abuildBible';
const URL_PARTS_OFFER_LIST = '/offers';
const URL_PARTS_REFUSAL_LIST = '/refusalies';

const URL_PATH_PARAM_CORPORATIONID = ':corporationId?';
const URL_PATH_PARAM_EVENTID = ':eventId?';
const URL_PATH_PARAM_TRAINEEID = ':traineeId?';
const URL_PATH_PARAM_TIMESTAMP = ':timestamp?';
const URL_PATH_PARAM_TTMID = ':ttmid?';
const URL_PATH_PARAM_CHATROOMID = ':chatroomId?';
const URL_PATH_PARAM_NOTIFID = ':notifId?';
const URL_PATH_PARAM_PCMODE = ':pcMode?';
const URL_PATH_PARAM_T_ROLE = ':tgdrole?';
const URL_PATH_PARAM_T_USER_ID = ':tgduserid?';


export const PARAM_PCMODE_TRUE = 'onMode';

export const TEST_SPS_URL = 'https://docs.google.com/spreadsheets/d/10pST70Jlj8aLlKsrd6rhLaresb56bdQ5qvyzWM76SZ0/edit#gid=0';

export const URL_PATH_MODULE_TEST_3_UID = URL_PATH_MODULE_TEST_3  + URL_PARTS_SLASH + URL_PATH_PARAM_T_USER_ID + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_CS_MODULE_TEST_1_TIMESTAMP_TTMID = URL_PATH_CS_MODULE_TEST_1 + URL_PARTS_SLASH + URL_PATH_PARAM_T_USER_ID + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP + URL_PARTS_SLASH + URL_PATH_PARAM_TTMID;

export const URL_PATH_SCHEDULE_DETAIL = URL_PATH_SCHEDULE + URL_PARTS_DETAIL;
export const URL_PATH_SCHEDULE_DETAIL_PARAM_CORPORATIONID = URL_PATH_SCHEDULE_DETAIL + URL_PARTS_SLASH + URL_PATH_PARAM_CORPORATIONID;
export const URL_PATH_SCHEDULE_READ = URL_PATH_SCHEDULE + URL_PARTS_READ;
export const URL_PATH_SCHEDULE_READ_PARAM_CORPORATIONID = URL_PATH_SCHEDULE_READ + URL_PARTS_SLASH + URL_PATH_PARAM_CORPORATIONID;
export const URL_PATH_SCHEDULE_READ_IEEDITOR = URL_PATH_SCHEDULE_READ + URL_PARTS_IEEDITOR;
export const URL_PATH_SCHEDULE_READ_IEEDITOR_PARAM_EVENTID = URL_PATH_SCHEDULE_READ_IEEDITOR + URL_PARTS_SLASH + URL_PATH_PARAM_EVENTID;
export const URL_PATH_IEEDITOR = URL_PATH_SCHEDULE + URL_PARTS_DETAIL + URL_PARTS_IEEDITOR;
export const URL_PATH_IEEDITOR_PARAM_EVENTID = URL_PATH_IEEDITOR + URL_PARTS_SLASH + URL_PATH_PARAM_EVENTID;
export const URL_PATH_ESEDITOR = URL_PATH_SCHEDULE + URL_PARTS_DETAIL + URL_PARTS_ESEDITOR;
export const URL_PATH_ESEDITOR_PARAM_EVENTID = URL_PATH_ESEDITOR + URL_PARTS_SLASH + URL_PATH_PARAM_EVENTID;
export const URL_PATH_TASKTIMES_PARAM_TRAINEEID = URL_PATH_TASKTIMES + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_TASKTIMES_PARAM_TIMESTAMP_TTMID = URL_PATH_TASKTIMES + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP + URL_PARTS_SLASH + URL_PATH_PARAM_TTMID;
export const URL_PATH_TASKTIMES_DETAIL = URL_PATH_TASKTIMES + URL_PARTS_DETAIL;
export const URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID = URL_PATH_TASKTIMES + URL_PARTS_DETAIL + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP + URL_PARTS_SLASH + URL_PATH_PARAM_TTMID;
export const URL_PATH_TASKTIMES_DETAIL_TIMESTAMP_TTMID_TRAINEEID = URL_PATH_TASKTIMES + URL_PARTS_DETAIL + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP + URL_PARTS_SLASH + URL_PATH_PARAM_TTMID + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_TASKTIMES_CREATE_TIMESTAMP = URL_PATH_TASKTIMES_CREATE + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP;
export const URL_PATH_TASKTIMES_CREATE_TIMESTAMP_PCMODE = URL_PATH_TASKTIMES_CREATE + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP + URL_PARTS_SLASH + URL_PATH_PARAM_PCMODE;
export const URL_PATH_CHATROOMS_DETAIL = URL_PATH_CHATROOMS + URL_PARTS_DETAIL;
export const URL_PATH_CHATROOMS_TEST = URL_PATH_CHATROOMS_T + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID + URL_PARTS_SLASH + URL_PATH_PARAM_T_USER_ID;
export const URL_PATH_CHATROOMS_DETAIL_CHATROOMID = URL_PATH_CHATROOMS + URL_PARTS_DETAIL + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CHATROOMS_DETAIL_CS = URL_PATH_CHATROOMS + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_SUPPORT_CHATROOMID = URL_PATH_CS_CHAT_SUPPORT + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_OPERATION_CHATROOMID = URL_PATH_CS_CHAT_OPERATION + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_APP_SUPPORT_CHATROOMID = URL_PATH_CS_CHAT_APP_SUPPORT + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_SUPPORT_COACH_CHATROOMID = URL_PATH_CS_CHAT_SUPPORT_COACH + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_OPERATION_COACH_CHATROOMID = URL_PATH_CS_CHAT_OPERATION_COACH + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_APP_SUPPORT_COACH_CHATROOMID = URL_PATH_CS_CHAT_APP_SUPPORT_COACH + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_CS_CHAT_COACHING_CHATROOMID = URL_PATH_CS_CHAT_COACHING + URL_PARTS_SLASH + URL_PATH_PARAM_CHATROOMID;
export const URL_PATH_MYPAGE_TRAINEE_RULES = URL_PATH_MYPAGE + URL_PARTS_TRAINEE_RULES;
export const URL_PATH_MYPAGE_MY_ES_LIST = URL_PATH_MYPAGE + URL_PARTS_MY_ES_LIST;
export const URL_PATH_MYPAGE_PROFILE_EDIT = URL_PATH_MYPAGE + URL_PARTS_PROFILE_EDIT;
export const URL_PATH_MYPAGE_ABUILD_BIBLE = URL_PATH_MYPAGE + URL_PARTS_ABUILD_BIBLE;
export const URL_PATH_MYPAGE_REFUSAL_LIST = URL_PATH_MYPAGE + URL_PARTS_REFUSAL_LIST;
export const URL_PATH_MYPAGE_OFFER_LIST = URL_PATH_MYPAGE + URL_PARTS_OFFER_LIST;
export const URL_PATH_NOTIFICATIONS_NOTIFID = URL_PATH_NOTIFICATIONS + URL_PARTS_SLASH + URL_PATH_PARAM_NOTIFID;
export const URL_PATH_TRAINEE_LIST_TRAINEEID = URL_PATH_TRAINEE_LIST + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_TRAINEE_LIST_DETAIL = URL_PATH_TRAINEE_LIST + URL_PARTS_DETAIL;
export const URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID = URL_PATH_TRAINEE_LIST_DETAIL + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID_IEEDITOR = URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID + URL_PARTS_IEEDITOR;
export const URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID_IEEDITOR_EVENTID = URL_PATH_TRAINEE_LIST_DETAIL_TRAINEEID_IEEDITOR + URL_PARTS_SLASH + URL_PATH_PARAM_EVENTID;
export const URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID = URL_PATH_CS_TRAINEE_MANAGEMENT + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID;
export const URL_PATH_CS_TRAINEE_MANAGEMENT_TRAINEEID_TTMID_TIMESTAMP = URL_PATH_CS_TRAINEE_MANAGEMENT + URL_PARTS_SLASH + URL_PATH_PARAM_TRAINEEID + URL_PARTS_SLASH + URL_PATH_PARAM_TTMID + URL_PARTS_SLASH + URL_PATH_PARAM_TIMESTAMP;

export const HEADER_TITLE_TASKTIMES = 'タスクマネジメント';
export const HEADER_TITLE_CHATROOMS = 'チャットルーム';
export const HEADER_TITLE_TRAINEE_LIST = 'トレーニーリスト';
export const HEADER_TITLE_TRAINEE_MANAGEMENT = 'トレーニー管理';
export const HEADER_TITLE_COACH_MANAGEMENT = 'コーチ管理';
export const HEADER_TITLE_EVENT_BOOKING_STATUS = 'イベント予約状況';
export const HEADER_TITLE_TRAINEE_DETAIL = 'トレーニー詳細';
export const HEADER_TITLE_MYPAGE = 'マイページ';
export const HEADER_TITLE_MASTER_MANAGEMENT = 'マスタ管理';
export const HEADER_TITLE_TRAINEE_DATA_MANAGEMENT = 'トレーニー管理';
export const HEADER_TITLE_COACH_DATA_MANAGEMENT = 'コーチ管理';
export const HEADER_TITLE_COACH_ASSGIN_MANAGEMENT = 'コーチアサイン管理';
export const HEADER_TITLE_TODAY_WORDS_MASTER_MANAGEMENT = '新井翔太からの今日のメッセージマスタデータ管理';
export const HEADER_TITLE_CORPORATION_MASTER_MANAGEMENT = '企業マスタデータ管理';
export const HEADER_TITLE_TRAINEE_MASTER_MANAGEMENT = 'トレーニーユーザーマスタデータ管理';
export const HEADER_TITLE_COACH_MASTER_MANAGEMENT = 'コーチユーザーマスタデータ管理';

export const FOOTER_TITLE_SCHEDULE = '選考状況';
export const FOOTER_TITLE_TASKTAIMES = 'タスク';
export const FOOTER_TITLE_CHATROOMS = 'チャット';
export const FOOTER_TITLE_SUBJECTS = 'Abuildコンテンツ';
export const FOOTER_TITLE_MYPAGE = 'マイページ';
export const FOOTER_TITLE_COACHING_MANAGEMENT = '面談管理';
export const FOOTER_TITLE_TRAINEE_LIST = 'TR一覧';
export const FOOTER_TITLE_MANUAL = 'マニュアル';

export const DETAIL_OFFER_HEADER_TITLE = '内定済み:';
export const DETAIL_OFFER_COMPLETE_HEADER_TITLE = '内定確定済み:';
export const DETAIL_REF_OFFER_HEADER_TITLE = '辞退済み:';
export const DETAIL_FORMAT_HEADER_TITLE = '選考状況:';
export const TAB_MENU_TITLE_CARENDAR = 'カレンダー';
export const TAB_MENU_TITLE_LIST = 'リスト';
export const MESSAGE_EVENT_NOT_COUNT = 'イベントがありません。下部の追加ボタンからイベントを追加してください。';
export const MESSAGE_COPY_COMPLETE = 'コピーしました。';
export const MESSAGE_ABUILDSHEETS_NON_DATA = 'データがありません。運営に確認をしてください。';
export const DIALOG_CAPTION_REFUSAL_CONFIRM = '辞退確認';
export const DIALOG_CONTENT_TEXT_REFUSAL_CONFIRM = 'この企業の就職活動を辞退します。よろしいですか？';
export const DIALOG_CANCEL_TEXT_REFUSAL_CONFIRM = '辞退しない';
export const DIALOG_AGREE_TEXT_REFUSAL_CONFIRM = '辞退する';
export const DIALOG_CAPTION_REGIST_CONFIRM = '内定確認';
export const DIALOG_CONTENT_TEXT_REGIST_CONFIRM = 'この企業を内定企業として登録します。よろしいですか？';
export const DIALOG_CANCEL_TEXT_REGIST_CONFIRM = '登録しない';
export const DIALOG_AGREE_TEXT_REGIST_CONFIRM = '登録する';
export const DIALOG_CAPTION_REF_OFFER_DELETE_CONFIRM = '選考状態に戻す';
export const DIALOG_CONTENT_TEXT_REF_OFFER_DELETE_CONFIRM = 'この企業の就職活動状態を選考状態に戻します。よろしいですか？';
export const DIALOG_CANCEL_TEXT_REF_OFFER_DELETE_CONFIRM = '戻さない';
export const DIALOG_AGREE_TEXT_REF_OFFER_DELETE_CONFIRM = '戻す';
export const DIALOG_CAPTION_OFFER_DELETE_CONFIRM = '取消確認';
export const DIALOG_CONTENT_TEXT_OFFER_DELETE_CONFIRM = 'この企業の就職活動を再開します。よろしいですか？';
export const DIALOG_CANCEL_TEXT_OFFER_DELETE_CONFIRM = '再開しない';
export const DIALOG_AGREE_TEXT_OFFER_DELETE_CONFIRM = '再開する';
export const DIALOG_CAPTION_CONSENT_CONFIRM = '内定承諾確認';
export const DIALOG_CONTENT_TEXT_CONSENT_CONFIRM = 'この確認以降、この企業向けの予定を作成出来ず、確認が出来るのみとなります。承諾してもよろしいですか？';
export const DIALOG_CANCEL_TEXT_CONSENT_CONFIRM = '承諾しない';
export const DIALOG_AGREE_TEXT_CONSENT_CONFIRM = '承諾する';
export const DIALOG_TTM_COMMIT_ERROR_CAPTION = 'タスク確認依頼エラー';
export const DIALOG_TTM_COMMIT_ERROR_CONTENT_TEXT = 'タスク内容、または目標・意気込みに不備があります。確認してください。';
export const BUTTON_CAPTION_REFUSAL_OFFER_REGIST = '辞退';
export const BUTTON_CAPTION_OFFER_REGIST = '内々定';
export const BUTTON_CAPTION_OFFER_DELETE = '選考状態に戻す';
export const BUTTON_CAPTION_OFFER_CONFIRM = '内定承諾';
export const BUTTON_CAPTION_REF_OFFER_DELETE = '辞退解除';
export const DETAIL_TYPE_LABEL = '形式';
export const DETAIL_TYPE_HELPER_TEXT = '（オンライン・対面・GD・AI・その他）';
export const INTERVIEW_DATE_HEADER_TITLE = '面接日:';
export const MEMO_EDITOR_CAPTION = 'MEMO';
export const CAPTION_CORPORATION_NAME = '企業名';
export const CAPTION_REFUSAL_REGISTRATION_DATE = '辞退登録日';
export const CAPTION_OFFER_REGISTRATION_DATE = '内定登録日';
export const DETAIL_EDITOR_CARD_NUMBER_CAPTION = '問';
export const DETAIL_EDITOR_CARD_HELPER_TEXT = '（問われている内容を入力してください）';
export const DETAIL_EDITOR_CARD_CHAR_COUNT = '文字数：';
export const BUTTON_CAPTION_SAVE = '保存';
export const DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY = '過去の回答一覧';
export const DETAIL_EDITOR_CARD_CAPTION_ANSWERS_HISTORY_CHECK = '（以前の回答を見る）';
export const BUTTON_CAPTION_INTERN = 'インターン';
export const BUTTON_CAPTION_DETAIL_WRITING = '内容記載';
export const BUTTON_CAPTION_ENTRYSHEET_CREATE = 'ES作成';
export const BUTTON_CAPTION_INTERVIEW_DETAIL_WRITING = '面接内容記載';
export const BUTTON_CAPTION_DETAIL_CHECK = '内容確認';
export const BUTTON_CAPTION_ENTRYSHEET_CHECK = 'ES確認';
export const BUTTON_CAPTION_INTERVIEW_DETAIL_CHECK = '面接内容確認';
export const EVENT_CARD_CAPTION_EVENT_NAME = 'イベント名';
export const EVENT_CARD_CAPTION_EVENT_START_DATETIME = 'イベント開始日時';
export const DIALOG_CAPTION_EVENT_DELETE_CONFIRM = '削除確認';
export const DIALOG_CONTENT_TEXT_EVENT_DELETE_CONFIRM = 'このイベントを削除します。よろしいですか？';
export const DIALOG_CANCEL_TEXT_EVENT_DELETE_CONFIRM = '削除しない';
export const DIALOG_AGREE_TEXT_EVENT_DELETE_CONFIRM = '削除する';
export const ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT = '確認する';
export const STATUS_CAPTION_REFUSAL_OFFER = '辞退';
export const STATUS_CAPTION_OFFER = '内定';
export const DATA_CARD_CAPTION_DEADLINE = '期限';
export const MESSAGE_EMPTY_NOT_REGISTRATION = '空白では登録できません。';
export const DIALOG_EVENT_CREATE_ATTRIBUTE_EVENT_TYPE = 'イベント種別';
export const BUTTON_CAPTION_CREATE = '作成';
export const ANSWER_CARD_CAPTION_COACH_COMMENT = 'コーチコメント';
export const OFFER_STATUS_COMPLETE = '内定承諾';

export const TASLTIMES_TASK_DETAIL_CAREFULL_MESSAGE = 'タスク内容を入力してください。';
export const TASLTIMES_PROMPT_FILL_MESSAGE = '記入してください。';

export const TASK_TIME_DETAIL_HEADER_TITLE = 'タスク報告:';
export const TAB_MENU_TITLE_PLANS = '予定';
export const TAB_MENU_TITLE_ACHIVEMENT = '実績';
export const TASK_TITLE_TODAY_TARGETS = '今日の目標'
export const TASK_TITLE_TODAY_EFFORT_TARGETS = '今日の意気込み';
export const TASK_TITLE_TODAY_REFLECTION = '＜1日の振り返り＞';
export const TASK_TITLE_TASK_ACHIEVEMENT = 'タスク達成率';
export const TASK_TITLE_REFLECTION_ON_DAY = '1日の反省・感想';
export const TASK_TITLE_ISSUES_TO_IMPROVE = '改善すべき課題';
export const TASK_TITLE_ACTION_TO_IMPROVE = '改善するための行動';
export const TASK_ATTRIBUTE_TITLE_START_TIME = '開始';
export const TASK_ATTRIBUTE_TITLE_END_TIME = '終了';
export const TASK_ATTRIBUTE_TITLE_AHIVEMENT = '進捗';
export const TASK_ATTRIBUTE_TITLE_TASK_CONTENTS = 'タスク内容';
export const TODAYS_SHOTA_ARAI_HEADER_TITLE = '新井翔太からの今日のメッセージ';

export const ABUILD_SUBJECTS_HEADER_TITLE = 'Abuildコンテンツ';
export const ABUILD_SUBJECTS_CAPTION_ABUILDSHEETS = 'Abuild戦略特訓シート';
export const ABUILD_SUBJECTS_CAPTION_SURVEY = 'アンケート';
export const ABUILD_SUBJECTS_CAPTION_E_LEARNING = 'E-Learning';
export const ABUILD_SUBJECTS_CAPTION_CONTENTS = 'プログラムコンテンツ';

export const CHAT_STATUS_READED = '既読';
export const CHAT_STATUS_ALREADY_READ = '既読済';

export const C_MANAGEMENT_BUTTON_CAPTION_MANAGEMENT_SHEET_OPEN = '面談管理シートを開く（別タブで開きます）'
export const C_MANAGEMENT_BUTTON_CAPTION_REPORT_OPEN = '報告書を開く（別タブで開きます）';

export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_COACHING = 'CO-TR Chat（管理）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT = 'サクセスサポート（TR）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION = 'Abuild公式通知（TR）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_PAYMENT = 'ペイメント（TR）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT = 'アプリ問合せ窓口（TR）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_SUPPORT_COACH = 'サクセスサポート（CO）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_OPERATION_COACH = 'Abuild公式通知（CO）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_PAYMENT_COACH = 'ペイメント（CO）';
export const CUSTOMER_SUCCESS_MENU_CHATROOM_NAME_APP_SUPPORT_COACH = 'アプリ問合せ窓口（CO）';
export const CUSTOMER_SUCCESS_MENU_TRAINEE_MANAGEMENT = 'トレーニー管理';
export const CUSTOMER_SUCCESS_MENU_COACH_MANAGEMENT = 'コーチ管理';
export const CUSTOMER_SUCCESS_MENU_EVENT_BUKKING_STATUS = 'イベント予約状況';

export const ADMINISTRATOR_MENU_MASTER_MANAGEMENT = 'マスタ管理';

export const ERROR_TYPE_ABORTERROR = 'AbortError';

export const CALENDAR_VIEW_MONTH = 'month';

export const TODAYS_WORDS_MANAGEMENT_HEADER_TITLE = '今日の一言管理';
export const CORPORATION_MANAGEMENT_HEADER_TITLE = '企業管理';

export const PROFILE_EDIT_CAPTION_LAST_NAME = '姓';
export const PROFILE_EDIT_CAPTION_FIRST_NAME = '名';
export const PROFILE_EDIT_CAPTION_LAST_NAME_KANA = '姓（フリガナ）';
export const PROFILE_EDIT_CAPTION_FIRST_NAME_KANA = '名（フリガナ）';
export const PROFILE_EDIT_CAPTION_DISPLAY_NAME = '表示名';
export const PROFILE_EDIT_CAPTION_BIRTH_DATE = '生年月日';
export const PROFILE_EDIT_CAPTION_WEEKDAY_PHONE_NUMBER = '電話番号';
export const PROFILE_EDIT_CAPTION_PREF_NAME = '都道府県';
export const PROFILE_EDIT_CAPTION_CWTV_NAME = '市区町村';
export const PROFILE_EDIT_CAPTION_STREET_ADDRESS = '番地以下';
export const PROFILE_EDIT_CAPTION_APARTMENT_NAME = 'アパート・マンション名';
export const PROFILE_EDIT_CAPTION_HIGHSCHOOL_NAME = '高校名';
export const PROFILE_EDIT_CAPTION_UNIVERSITY_NAME = '大学名';
export const PROFILE_EDIT_CAPTION_DEPARTMENT_NAME = '学部名';
export const PROFILE_EDIT_CAPTION_COURCE_NAME = '学科名';
export const PROFILE_EDIT_CAPTION_GRADUATION_YEAR = '大学卒業年度';
export const PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_NAME = '大学院名';
export const PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_DEPARTMENT_NAME = '専攻名';
export const PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_COURSE_NAME = 'コース名';
export const PROFILE_EDIT_CAPTION_GRADUATE_SCHOOL_GRADUATION_YEAR = '大学院卒業年度';
export const PROFILE_EDIT_CAPTION_EMERGENCY_PHONE_NUMBER = '緊急連絡先電話番号';
export const PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_NAME = '緊急連絡先の氏名';
export const PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_TYPE = '緊急連絡先の続柄';
export const PROFILE_EDIT_CAPTION_EMERGENCY_PERSON_ADDRESS = '緊急連絡先の住所';
export const PROFILE_EDIT_CAPTION_BANK_NAME = '銀行名';
export const PROFILE_EDIT_CAPTION_BANK_BRANCH_NAME = '支店名';
export const PROFILE_EDIT_CAPTION_BANK_BRANCH_TYPE = '口座種別';
export const PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NUMBER = '口座番号';
export const PROFILE_EDIT_CAPTION_BANK_ACCOUNT_NAME = '名義（カタカナ）';
export const PROFILE_EDIT_CAPTION_REMARKS = '備考';
export const PROFILE_EDIT_CAPTION_PREF_CODE = '都道府県コード';
export const PROFILE_EDIT_CAPTION_MIDDLE_NAME = 'ミドルネーム';
export const PROFILE_EDIT_CAPTION_MIDDLE_NAME_KANA = 'ミドルネーム（カナ）';
export const PROFILE_EDIT_CAPTION_USER_ROLE = 'ユーザーロール';
export const PROFILE_EDIT_CAPTION_EMAIL_ADDRESS = 'Eメールアドレス';

export const TRAINEE_MASTER_MANAGEMENT_USER_LIST_NAME = 'ユーザーリスト';

export const COACH_MASTER_MANAGEMENT_USER_LIST_NAME = 'コーチリスト';
export const FORM_NAME_COGNITO_USER_ID = 'CognitoユーザーID';

export const BATCH_SENDING_DIALOG_CAPTION = '一括送信';
export const BATCH_SENDING_DIALOG_AGREE_TEXT = '送信する';
export const BATCH_SENDING_DIALOG_CANCEL_TEXT = 'キャンセル';
