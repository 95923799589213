// AWS
import { API } from "aws-amplify";

// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Grid, Button } from '@material-ui/core';

// GraphQL
import { 
    listWeeklySurveyDatas, 
    listAbuildSheetsDatas,
    listELearningMovieDatas,
    listAdditionalServiceUrls, 
} from "../../graphql/queries";

// Self Making Files
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { 
    ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT, 
    ABUILD_SUBJECTS_CAPTION_ABUILDSHEETS, 
    ABUILD_SUBJECTS_CAPTION_CONTENTS, 
    ABUILD_SUBJECTS_CAPTION_E_LEARNING, 
    ABUILD_SUBJECTS_CAPTION_SURVEY, 
    ABUILD_SUBJECTS_HEADER_TITLE, 
    AMAZON_COGNITO_USER_POOLS,
    ERROR_TYPE_ABORTERROR,
    INITIAL_QUERY, 
    MESSAGE_ABUILDSHEETS_NON_DATA,
    USER_ROLE, 
} from '../../Commons/Consts'
import { 
    getCurrentUserData, 
    consoleLog, 
    LineTextComponent, 
    redirectForUserRoleToRedorectPages, 
} from '../../Commons/CommonFunctions';

import { useHistory } from "react-router";
import { 
    surveyReducer, 
    abuildSheetsReducer,
    eLearingMovesReducer,
    commonDBDataReducer,
    userReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { LoadingIcon } from "../../Commons/LoadingIcon";
import CommonDialog from "../../Commons/CommonDialog";
import SideMenu from "../../Commons/SideMenu";
  
const localStyles = makeStyles(theme => ({
    paddingRow:{
        padding:10,
    },
    contentsBox:{
        backgroundColor: theme.palette.background.boxPaper,
        padding: "10px",
        marginTop: "5px",
        marginLeft: "5px",
    },
    rightContentsBox:{
        backgroundColor: theme.palette.background.boxPaper,
        padding: "10px",
        marginTop: "5px",
    },
    longContentsBox:{
        backgroundColor: theme.palette.background.boxPaper,
        padding: "15px",
        marginTop: "5px",
        marginLeft: "5px",
    },
    abuildSheetsButton:{
        padding: "40px 40px",
        display: "inline-block",
        minWidth: "100%",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        cursor: "pointer",
        fontSize: "20px",
        backgroundColor: theme.palette.action.main,
        color: theme.palette.primary.main,
        textDecoration:"none",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
    },
    contentsButton:{
        padding: "30px 30px",
        display: "inline-block",
        minWidth: "100%",
        width: "100%",
        minHeight: "100%",
        height: "100%",
        cursor: "pointer",
        fontSize: "20px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        textDecoration:"none",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
    },
    surverButton:{
        padding: "16px 6px 16px 6px",
        display: "inline-block",
        minWidth: "100%",
        width: "100%",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        textDecoration:"none",
        border: "none",
        borderRadius: "5px",
        textAlign: "center",
    },
}));

function AbuildSheetsButtonList({ abulidSheets }){
    const localStyle = localStyles();
    const [nonDataWarningOpen, setNonDataWarningOpen] = useState(false);

    const agreeEvent = () => {
        
    }

    return (
        <div>
            { 0 < abulidSheets.length ?
                <Grid container>
                    {
                        abulidSheets
                        .sort((a, b) => {return a.weeklyNumber < b.weeklyNumber ? -1 : 1})
                        .filter((sheets) => {return sheets.weeklyNumber <= 12})
                        .map((sheets) => {
                            return(
                            <Grid item xs={12} className={localStyle.paddingRow} >
                                <a 
                                    rel="noreferrer" 
                                    target="_blank" 
                                    href={sheets.abuildSheetsUrl} 
                                    className={localStyle.abuildSheetsButton}
                                >
                                    <LineTextComponent msg={sheets.captionTitle} />
                                </a>
                            </Grid>)
                        })
                    }
                </Grid>
            :
            <Grid container>
                <Grid item xs={12} className={localStyle.paddingRow}>
                    <Button variant="contained" className={localStyle.abuildSheetsButton} onClick={() => {setNonDataWarningOpen(true)}} fullWidth>戦略特訓シートを開く（別タブで開く）</Button>
                </Grid>
            </Grid>
            }
            <CommonDialog 
                agreeEvent={agreeEvent}
                open={nonDataWarningOpen}
                setOpen={setNonDataWarningOpen}
                contentText={MESSAGE_ABUILDSHEETS_NON_DATA}
                agreeText={ABUILDSHEETS_NON_DATA_DIALOG_AGREE_TEXT}
            />
        </div>
    )
}

function SurveyButtonList({ survey }){
    const localStyle = localStyles();
    
    return(
        <div>
            {0 < survey.length ?
            <Grid container>
                { survey.sort((a, b) => {return a.weeklyNumber < b.weeklyNumber ? -1 : 1}).map((data) => {
                    return (
                    <Grid item xs={6} className={localStyle.paddingRow} >
                        <a 
                            rel="noreferrer" 
                            target="_blank" 
                            href={data.surveyUrl}
                            className={localStyle.surverButton}
                        >
                            <LineTextComponent msg={data.captionTitle} />
                        </a>
                    </Grid>)
                })}
            </Grid>
            :
            <Fragment></Fragment>
            }
        </div>
    );
}

function ProgramContentsButtonList({ contents }){
    const localStyle = localStyles();
    
    return(
        <div>
            {0 < contents.length ?
            <Grid container>
                { contents.sort((a,b) => a.createdAt < b.createdAt ? -1 : 1).map((data) => {
                    return (
                        <Grid item xs={6} className={localStyle.paddingRow} >
                            <a 
                                rel="noreferrer" 
                                target="_blank" 
                                href={data.url}
                                className={localStyle.contentsButton}
                            >
                                <LineTextComponent msg={data.captionTitle} />
                            </a>
                        </Grid>)
                })}
            </Grid>
            :
            <Fragment></Fragment>
            }
        </div>
    );
}

export default function AbuildSubjects(){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    
    const [survey, dispatchSurvey] = useReducer(surveyReducer, []);
    const [abulidSheets, dispatchAbuildSheets] = useReducer(abuildSheetsReducer, []);
    const [contents, dispatchContents] = useReducer(commonDBDataReducer, []);
    const [userData, dispachUser] = useReducer(userReducer, []);
    const initAbuildSubjects = async () =>{
        try{
            const currentUserData = await getCurrentUserData();
            redirectForUserRoleToRedorectPages(history, currentUserData, USER_ROLE.Trainee);
            dispachUser({ type: INITIAL_QUERY, user:currentUserData});
            
            const surveyDataRes = await API.graphql({
                query: listWeeklySurveyDatas,
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            const surveyData = surveyDataRes.data.listWeeklySurveyDatas.items;
            dispatchSurvey({ type:INITIAL_QUERY, surveies:surveyData });

            const abuildSheetsDatafilter = {
                userDatasID: {contains: currentUserData.userId},
            }
            const abuildSheetsDataRes = await API.graphql({
                query: listAbuildSheetsDatas,
                variables: { filter: abuildSheetsDatafilter, limit:10000 },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchAbuildSheets({ type:INITIAL_QUERY, sheets:abuildSheetsDataRes.data.listAbuildSheetsDatas.items });

            const contentsDataRes = await API.graphql({
                query: listAdditionalServiceUrls,
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchContents({ type:INITIAL_QUERY, data:contentsDataRes.data.listAdditionalServiceUrls.items });

           setIsLoading(false);
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let abortCtrl = new AbortController();

        initAbuildSubjects();
        // アンマウント時処理
        return() => {
            abortCtrl.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Header title={ABUILD_SUBJECTS_HEADER_TITLE}/>
            <SideMenu/>
            {isLoading?
            <LoadingIcon/>
            :
            <Box>
                <Box className={commonStyle.mobileModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <Box className={commonStyle.endBox} />
                    </Box>
                </Box>
                <Box className={commonStyle.pcModeDisplay}>
                    <Box className={commonStyle.pageContent} >
                        <Box pt={2}/>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <AbuildSheetsButtonList abulidSheets={abulidSheets} />
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <ProgramContentsButtonList contents={contents} />
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                        <Grid container>
                            <Grid item xs={1} />
                            <Grid item xs={10} className={commonStyle.centerContents}>
                                <Typography variant="h6">アンケート</Typography>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <SurveyButtonList survey={survey} />
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </Box>
                </Box>
            </Box>
            }
            <Footer/>
        </Box>
    );
}
