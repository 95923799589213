// React
import { 
    useEffect, 
    useState, 
    useReducer,
    Fragment, 
} from 'react';
import { useHistory } from 'react-router-dom';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Grid, 
    Avatar, 
    IconButton, 
} from '@material-ui/core';

// Self Making Files
import { ArrowForwardIos } from '@material-ui/icons';
import * as Consts from '../../Commons/Consts';
import { 
    getUserDataById, 
    getUserIconImage, 
    getCurrentUserData, 
    consoleLog, 
} from "../../Commons/CommonFunctions";

import { 
    userDataReducer, 
} from "../../Commons/Reducers";
import { commonStyles } from "../../Commons/CommonStyles";
import { API } from 'aws-amplify';
import { listTraineeDatas } from '../../graphql/queries';
import { TagsViewer } from './ChatroomDetailViewer';

const localStyles = makeStyles(theme => ({
    roomCard:{
        padding: "10px",
        marginBottom: "1px",
        minHeight: "68px",
        cursor: "pointer",
        backgroundColor: theme.palette.background.paper,
        border: "solid #AAAAAA 1px",
    },
    loader: {
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        height:"100%",
        width:"100%",
        paddingBottom: "12px",
    },
}));
  
export default function CustomerTraineeList({ trainees, traineeId, pcMode }){
    return (
        <div>
            {trainees.length <= 0 ? 
            <Fragment></Fragment>
            :  
            trainees.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((trainee) => {
                return (<CustomerTraineeListItem
                    trainee={trainee}
                    traineeId={traineeId}
                    pcMode={pcMode}
                />)
            })
            }
        </div>
    );
}

function CustomerTraineeListItem({ trainee, traineeId, pcMode }){
    const history = useHistory();
    const commonStyle = commonStyles();
    const localStyle = localStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [traineeUserData, dispatchTraineeUserData] = useReducer(userDataReducer, []);
    const [previewImage, setPreviewImage] = useState();
    const [userData, setUserData] = useState([]);
    
    const initTraineeDetail = async () =>{
        try {
            const currentUserData = await getCurrentUserData();
            setUserData(currentUserData);
            const tranieeData = await getUserDataById(trainee.userdatasID);
            dispatchTraineeUserData({type:Consts.INITIAL_QUERY, userData:tranieeData });
            if(tranieeData.iconImage !== null){
                const file = await getUserIconImage(tranieeData);
                setPreviewImage(file);
            }

            setIsLoading(false);
        } catch (e) {
            if (e.name === "AbortError") {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let abortCtrl = new AbortController();

        initTraineeDetail();
        // アンマウント時処理
        return() => {
            abortCtrl.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={localStyle.roomCard}
            rowSacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            onClick={() => {
                if(pcMode){
                    history.push(Consts.URL_PATH_CS_TRAINEE_MANAGEMENT + Consts.URL_PARTS_SLASH + trainee.userdatasID);
                }
                else{
                    history.push(Consts.URL_PATH_TRAINEE_LIST_DETAIL + Consts.URL_PARTS_SLASH + trainee.userdatasID);
                }
            }}
        >
            {isLoading ?
            <div className={localStyle.loader}>
                
            </div>
            :
            <Grid container>
                <Grid item xs={2} className={commonStyle.centerContents}>
                    <Avatar
                        src={previewImage}
                    />      
                </Grid>
                <Grid container xs={9}>
                    <Grid item xs={8}>
                        <Typography>{traineeUserData.lastName + ' ' + traineeUserData.firstName}</Typography>
                    </Grid>
                    {trainee.targetCSmemberName !== undefined ?
                    trainee.targetCSmemberName !== ''?
                    <Grid item xs={4}>
                        <Typography variant='caption'>{trainee.targetCSmemberName}</Typography>
                    </Grid>
                    :<Fragment></Fragment>
                    :<Fragment></Fragment>}
                    <TagsViewer tags={trainee.tag === null ? '' : trainee.tag} />
                </Grid>
                <Grid item xs={1} className={commonStyle.centerContents}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
            }
        </Grid>
    );
}
