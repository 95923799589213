// React
import React, { Fragment, useState } from 'react';

// Material-ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, TextField, Typography } from '@material-ui/core';

export default function CommonDialog({ agreeEvent, open, setOpen, caption, contentText, cancelText, agreeText }) {
    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = () => {
        agreeEvent();
        setOpen(false);
    };

    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {caption ?
            <DialogTitle id="alert-dialog-title">
                {caption}
            </DialogTitle>
            :
            <Fragment></Fragment>
            }
            {contentText ? 
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            :
            <Fragment></Fragment>
            }
            <DialogActions>
            {cancelText ?
            <Button onClick={handleClose} color="primary">
                {cancelText}
            </Button>
            :
            <Fragment></Fragment>
            }
            {agreeText ?
            <Button onClick={handleAgree} color="primary" autoFocus>
                {agreeText}
            </Button>
            :
            <Fragment></Fragment>
            }
            </DialogActions>
        </Dialog>
        </div>
    );
}

export function MessagingDialog({ open, setOpen, agreeEvent, captionTitle, inputCaptionName, cancelButtonText, agreeButtonText, setMessageObject }) {
    const [messageText, setMessageText] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = async () => {
        await agreeEvent();
        setOpen(false);
    };

    const messageTextOnChange = (event) => {
        setMessageText(event.target.value);
        setMessageObject(event.target.value);
    }
    
    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="batch-sending-dialog-title"
            aria-describedby="batch-sending-dialog-description"
            fullWidth
        >
            <DialogTitle id="batch-sending-dialog-title">
                {captionTitle} 
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='caption'>{inputCaptionName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={10}
                                onChange={messageTextOnChange}
                                value={messageText}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {cancelButtonText}
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                    {agreeButtonText}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}