// React
import React, { Fragment, useState } from 'react';

// Material-ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BATCH_SENDING_DIALOG_AGREE_TEXT, BATCH_SENDING_DIALOG_CANCEL_TEXT, BATCH_SENDING_DIALOG_CAPTION, CS_CHATROOM_TAGS } from '../../Commons/Consts';
import { Chip, FormControl, FormGroup, FormLabel, Grid, Input, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useTheme } from 'styled-components';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
            ? 500
            : 300,
    };
}
export default function BatchSendingDialog({ agreeEvent, open, setOpen, customerChatrooms }) {
    const [tagList, setTagList] = useState(CS_CHATROOM_TAGS);
    const [targetTagList, setTargetTagList] = useState([]);
    const [filterTerms, setFilterTerms] = useState([]);
    const [targetChatrooms, setTargetChatrooms] = useState([]);
    const [messageText, setMessageText] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const handleAgree = async () => {
        agreeEvent();
        setOpen(false);
    };

    const handleChange = (event) => {
        setTargetTagList(event.target.value);
        for(const tag of targetTagList){
            //console.log(customerChatrooms);
            //console.log(customerChatrooms.filter((chatroom) => {return -1 !== chatroom.tag.search(tag)}));
        }
    };

    const messageTextOnChange = (event) => {
        setMessageText(event.target.value);
    }
    
    const filterTermsListHandleChange = (event) => {
        setFilterTerms(event.target.value);
    };
    
    return (
        <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="batch-sending-dialog-title"
            aria-describedby="batch-sending-dialog-description"
            fullWidth
        >
            <DialogTitle id="batch-sending-dialog-title">
                {BATCH_SENDING_DIALOG_CAPTION} 
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant='caption'>メッセージ内容</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={10}
                                onChange={messageTextOnChange}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}><Typography variant='caption'>一括送信対象のタグと結合条件を選択してください。</Typography></Grid>
                    <Grid item xs={2}>
                        <FormControl >
                            <Select
                                id="demo-mutiple-name"
                                value={filterTerms}
                                onChange={filterTermsListHandleChange}
                                input={<Input />}
                                MenuProps={MenuProps}
                            >
                                <MenuItem key={'or'} value={'or'} >
                                    OR
                                </MenuItem>
                                <MenuItem key={'and'} value={'and'} >
                                    AND
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={10}>
                        <FormControl >
                            <Select
                                id="demo-mutiple-name"
                                multiple
                                value={targetTagList}
                                onChange={handleChange}
                                input={<Input />}
                                renderValue={(selected) => (
                                    <div>
                                      {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                      ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                                >
                                {tagList.map((name) => (
                                    <MenuItem key={name} value={name} style={getStyles(name, targetTagList)}>
                                    {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>*/}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {BATCH_SENDING_DIALOG_CANCEL_TEXT}
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                    {BATCH_SENDING_DIALOG_AGREE_TEXT}
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}