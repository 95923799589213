/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCSMemberDatas = /* GraphQL */ `
  query GetCSMemberDatas($id: ID!) {
    getCSMemberDatas(id: $id) {
      id
      userdatasID
      userName
      csChatroomType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCSMemberDatas = /* GraphQL */ `
  query ListCSMemberDatas(
    $filter: ModelCSMemberDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCSMemberDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userdatasID
        userName
        csChatroomType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatNotificationDatas = /* GraphQL */ `
  query GetChatNotificationDatas($id: ID!) {
    getChatNotificationDatas(id: $id) {
      id
      sendFromUserdatasID
      sendFromUserdatasName
      sendToUserdatasID
      sendToUserdatasName
      sendMessage
      sendFromStatus
      sendToStatus
      csStatus
      chatroomName
      Chatrooms {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      chatNotificationDatasChatroomsId
      __typename
    }
  }
`;
export const listChatNotificationDatas = /* GraphQL */ `
  query ListChatNotificationDatas(
    $filter: ModelChatNotificationDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatNotificationDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sendFromUserdatasID
        sendFromUserdatasName
        sendToUserdatasID
        sendToUserdatasName
        sendMessage
        sendFromStatus
        sendToStatus
        csStatus
        chatroomName
        Chatrooms {
          id
          chatroomUrl
          roomMasterUserDatasID
          ConectIsChatroomMembers {
            nextToken
            __typename
          }
          ChatDatas {
            nextToken
            __typename
          }
          CustomerChatrooms {
            nextToken
            __typename
          }
          lastMessage
          unReadFlg
          roomMasterName
          sendFromTimestamp
          sendToTimestamp
          sendFromDateTime
          sendToDateTime
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        chatNotificationDatasChatroomsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInitChatroomDatas = /* GraphQL */ `
  query GetInitChatroomDatas($id: ID!) {
    getInitChatroomDatas(id: $id) {
      id
      initChatroomType
      initMemberUserdatsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInitChatroomDatas = /* GraphQL */ `
  query ListInitChatroomDatas(
    $filter: ModelInitChatroomDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInitChatroomDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        initChatroomType
        initMemberUserdatsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdditionalServiceUrls = /* GraphQL */ `
  query GetAdditionalServiceUrls($id: ID!) {
    getAdditionalServiceUrls(id: $id) {
      id
      captionTitle
      url
      urlType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdditionalServiceUrls = /* GraphQL */ `
  query ListAdditionalServiceUrls(
    $filter: ModelAdditionalServiceUrlsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalServiceUrls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        captionTitle
        url
        urlType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerChatdatas = /* GraphQL */ `
  query GetCustomerChatdatas($id: ID!) {
    getCustomerChatdatas(id: $id) {
      id
      chatMessage
      chatDateTime
      chatDateTimestamp
      checkOn
      customerchatroomsID
      sendUserdatasID
      chatOnImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerChatdatas = /* GraphQL */ `
  query ListCustomerChatdatas(
    $filter: ModelCustomerChatdatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerChatdatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatMessage
        chatDateTime
        chatDateTimestamp
        checkOn
        customerchatroomsID
        sendUserdatasID
        chatOnImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerChatrooms = /* GraphQL */ `
  query GetCustomerChatrooms($id: ID!) {
    getCustomerChatrooms(id: $id) {
      id
      tag
      roomType
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userdatasID
      chatroomsID
      userdatasType
      roomMasterName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCustomerChatrooms = /* GraphQL */ `
  query ListCustomerChatrooms(
    $filter: ModelCustomerChatroomsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerChatrooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tag
        roomType
        CustomerChatdatas {
          items {
            id
            chatMessage
            chatDateTime
            chatDateTimestamp
            checkOn
            customerchatroomsID
            sendUserdatasID
            chatOnImage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userdatasID
        chatroomsID
        userdatasType
        roomMasterName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTodayWords = /* GraphQL */ `
  query GetTodayWords($id: ID!) {
    getTodayWords(id: $id) {
      id
      caption
      contentText
      displayNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodayWords = /* GraphQL */ `
  query ListTodayWords(
    $filter: ModelTodayWordsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodayWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caption
        contentText
        displayNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRefusalOfferDatas = /* GraphQL */ `
  query GetRefusalOfferDatas($id: ID!) {
    getRefusalOfferDatas(id: $id) {
      id
      userName
      corporationName
      userdatasID
      corporationsID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
        __typename
      }
      refusalOfferStatus
      createdAt
      updatedAt
      refusalOfferDatasScheduleEventsId
      __typename
    }
  }
`;
export const listRefusalOfferDatas = /* GraphQL */ `
  query ListRefusalOfferDatas(
    $filter: ModelRefusalOfferDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefusalOfferDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        corporationName
        userdatasID
        corporationsID
        ScheduleEvents {
          id
          eventName
          eventType
          status
          deadlineDateTime
          deadlineTimestamp
          formType
          eventColorcode
          EventDetialDatas {
            nextToken
            __typename
          }
          userDatasID
          corporationsID
          createdAt
          updatedAt
          __typename
        }
        refusalOfferStatus
        createdAt
        updatedAt
        refusalOfferDatasScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOfferDatas = /* GraphQL */ `
  query GetOfferDatas($id: ID!) {
    getOfferDatas(id: $id) {
      id
      userName
      corporationName
      corporationsID
      userdatasID
      ScheduleEvents {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
        __typename
      }
      offerStatus
      createdAt
      updatedAt
      offerDatasScheduleEventsId
      __typename
    }
  }
`;
export const listOfferDatas = /* GraphQL */ `
  query ListOfferDatas(
    $filter: ModelOfferDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfferDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        corporationName
        corporationsID
        userdatasID
        ScheduleEvents {
          id
          eventName
          eventType
          status
          deadlineDateTime
          deadlineTimestamp
          formType
          eventColorcode
          EventDetialDatas {
            nextToken
            __typename
          }
          userDatasID
          corporationsID
          createdAt
          updatedAt
          __typename
        }
        offerStatus
        createdAt
        updatedAt
        offerDatasScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getELearningMovieDatas = /* GraphQL */ `
  query GetELearningMovieDatas($id: ID!) {
    getELearningMovieDatas(id: $id) {
      id
      captionTitle
      movieUrl
      number
      userdatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listELearningMovieDatas = /* GraphQL */ `
  query ListELearningMovieDatas(
    $filter: ModelELearningMovieDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listELearningMovieDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        captionTitle
        movieUrl
        number
        userdatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklySurveyDatas = /* GraphQL */ `
  query GetWeeklySurveyDatas($id: ID!) {
    getWeeklySurveyDatas(id: $id) {
      id
      captionTitle
      surveyUrl
      weeklyNumber
      userdatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklySurveyDatas = /* GraphQL */ `
  query ListWeeklySurveyDatas(
    $filter: ModelWeeklySurveyDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklySurveyDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        captionTitle
        surveyUrl
        weeklyNumber
        userdatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAbuildSheetsDatas = /* GraphQL */ `
  query GetAbuildSheetsDatas($id: ID!) {
    getAbuildSheetsDatas(id: $id) {
      id
      abuildSheetsUrl
      captionTitle
      weeklyNumber
      userDatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAbuildSheetsDatas = /* GraphQL */ `
  query ListAbuildSheetsDatas(
    $filter: ModelAbuildSheetsDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAbuildSheetsDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        abuildSheetsUrl
        captionTitle
        weeklyNumber
        userDatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectIsCoachingToTargetTrainee = /* GraphQL */ `
  query GetConectIsCoachingToTargetTrainee($id: ID!) {
    getConectIsCoachingToTargetTrainee(id: $id) {
      id
      coachdatasID
      traineedatasID
      weekoneStatus
      weekoneCurriculumData
      weekoneSurveyData
      weektwoStatus
      weektwoCurriculumData
      weektwoSurveyData
      weekthreeStatus
      weekthreeCurriculumData
      weekthreeSurveyData
      weekfourStatus
      weekfourCurriculumData
      weekfourSurveyData
      weekfiveStatus
      weekfiveCurriculumData
      weekfiveSurveyData
      weeksixStatus
      weeksixCurriculumData
      weeksixSurveyData
      weeksevenStatus
      weeksevenCurriculumData
      weeksevenSurveyData
      weekeightStatus
      weekeightCurriculumData
      weekeightSurveyData
      weeknineStatus
      weeknineCurriculumData
      weeknineSurveyData
      weektenStatus
      weektenCurriculumData
      weektenSurveyData
      weekelevenStatus
      weekelevenCurriculumData
      weekelevenSurveyData
      weektwelveStatus
      weektwelveCurriculumData
      weektwelveSurveyData
      othorStatus
      othorCurriculumData
      othorSurveyData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConectIsCoachingToTargetTrainees = /* GraphQL */ `
  query ListConectIsCoachingToTargetTrainees(
    $filter: ModelConectIsCoachingToTargetTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConectIsCoachingToTargetTrainees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coachdatasID
        traineedatasID
        weekoneStatus
        weekoneCurriculumData
        weekoneSurveyData
        weektwoStatus
        weektwoCurriculumData
        weektwoSurveyData
        weekthreeStatus
        weekthreeCurriculumData
        weekthreeSurveyData
        weekfourStatus
        weekfourCurriculumData
        weekfourSurveyData
        weekfiveStatus
        weekfiveCurriculumData
        weekfiveSurveyData
        weeksixStatus
        weeksixCurriculumData
        weeksixSurveyData
        weeksevenStatus
        weeksevenCurriculumData
        weeksevenSurveyData
        weekeightStatus
        weekeightCurriculumData
        weekeightSurveyData
        weeknineStatus
        weeknineCurriculumData
        weeknineSurveyData
        weektenStatus
        weektenCurriculumData
        weektenSurveyData
        weekelevenStatus
        weekelevenCurriculumData
        weekelevenSurveyData
        weektwelveStatus
        weektwelveCurriculumData
        weektwelveSurveyData
        othorStatus
        othorCurriculumData
        othorSurveyData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachDatas = /* GraphQL */ `
  query GetCoachDatas($id: ID!) {
    getCoachDatas(id: $id) {
      id
      userdatasID
      corporationAffiliationID
      registrationDateTime
      registrationTimestamp
      deregistrationDateTime
      deregistrationTimestamp
      picturePublic
      namePublic
      interviewImplementation
      interviewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coachManageSheetUrl
      coachReportSheetUrl
      tag
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoachDatas = /* GraphQL */ `
  query ListCoachDatas(
    $filter: ModelCoachDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userdatasID
        corporationAffiliationID
        registrationDateTime
        registrationTimestamp
        deregistrationDateTime
        deregistrationTimestamp
        picturePublic
        namePublic
        interviewImplementation
        interviewPublic
        ConectIsCoachingToTargetTrainees {
          items {
            id
            coachdatasID
            traineedatasID
            weekoneStatus
            weekoneCurriculumData
            weekoneSurveyData
            weektwoStatus
            weektwoCurriculumData
            weektwoSurveyData
            weekthreeStatus
            weekthreeCurriculumData
            weekthreeSurveyData
            weekfourStatus
            weekfourCurriculumData
            weekfourSurveyData
            weekfiveStatus
            weekfiveCurriculumData
            weekfiveSurveyData
            weeksixStatus
            weeksixCurriculumData
            weeksixSurveyData
            weeksevenStatus
            weeksevenCurriculumData
            weeksevenSurveyData
            weekeightStatus
            weekeightCurriculumData
            weekeightSurveyData
            weeknineStatus
            weeknineCurriculumData
            weeknineSurveyData
            weektenStatus
            weektenCurriculumData
            weektenSurveyData
            weekelevenStatus
            weekelevenCurriculumData
            weekelevenSurveyData
            weektwelveStatus
            weektwelveCurriculumData
            weektwelveSurveyData
            othorStatus
            othorCurriculumData
            othorSurveyData
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        coachManageSheetUrl
        coachReportSheetUrl
        tag
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTraineeDatas = /* GraphQL */ `
  query GetTraineeDatas($id: ID!) {
    getTraineeDatas(id: $id) {
      id
      userdatasID
      corporationOfEmploymentID
      traineeStatus
      graduationDateTime
      graduationTimestamp
      registrationDateTime
      registrationTimstamp
      picturePublic
      namePublic
      interviewImplementation
      intervewPublic
      ConectIsCoachingToTargetTrainees {
        items {
          id
          coachdatasID
          traineedatasID
          weekoneStatus
          weekoneCurriculumData
          weekoneSurveyData
          weektwoStatus
          weektwoCurriculumData
          weektwoSurveyData
          weekthreeStatus
          weekthreeCurriculumData
          weekthreeSurveyData
          weekfourStatus
          weekfourCurriculumData
          weekfourSurveyData
          weekfiveStatus
          weekfiveCurriculumData
          weekfiveSurveyData
          weeksixStatus
          weeksixCurriculumData
          weeksixSurveyData
          weeksevenStatus
          weeksevenCurriculumData
          weeksevenSurveyData
          weekeightStatus
          weekeightCurriculumData
          weekeightSurveyData
          weeknineStatus
          weeknineCurriculumData
          weeknineSurveyData
          weektenStatus
          weektenCurriculumData
          weektenSurveyData
          weekelevenStatus
          weekelevenCurriculumData
          weekelevenSurveyData
          weektwelveStatus
          weektwelveCurriculumData
          weektwelveSurveyData
          othorStatus
          othorCurriculumData
          othorSurveyData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      curriculumStartDate
      traineeId
      tag
      ttmStatus
      targetCSmemberName
      targetCSmemberId
      ttmUpdateDateTime
      ttmUpdateTimestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTraineeDatas = /* GraphQL */ `
  query ListTraineeDatas(
    $filter: ModelTraineeDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraineeDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userdatasID
        corporationOfEmploymentID
        traineeStatus
        graduationDateTime
        graduationTimestamp
        registrationDateTime
        registrationTimstamp
        picturePublic
        namePublic
        interviewImplementation
        intervewPublic
        ConectIsCoachingToTargetTrainees {
          items {
            id
            coachdatasID
            traineedatasID
            weekoneStatus
            weekoneCurriculumData
            weekoneSurveyData
            weektwoStatus
            weektwoCurriculumData
            weektwoSurveyData
            weekthreeStatus
            weekthreeCurriculumData
            weekthreeSurveyData
            weekfourStatus
            weekfourCurriculumData
            weekfourSurveyData
            weekfiveStatus
            weekfiveCurriculumData
            weekfiveSurveyData
            weeksixStatus
            weeksixCurriculumData
            weeksixSurveyData
            weeksevenStatus
            weeksevenCurriculumData
            weeksevenSurveyData
            weekeightStatus
            weekeightCurriculumData
            weekeightSurveyData
            weeknineStatus
            weeknineCurriculumData
            weeknineSurveyData
            weektenStatus
            weektenCurriculumData
            weektenSurveyData
            weekelevenStatus
            weekelevenCurriculumData
            weekelevenSurveyData
            weektwelveStatus
            weektwelveCurriculumData
            weektwelveSurveyData
            othorStatus
            othorCurriculumData
            othorSurveyData
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        curriculumStartDate
        traineeId
        tag
        ttmStatus
        targetCSmemberName
        targetCSmemberId
        ttmUpdateDateTime
        ttmUpdateTimestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatDatas = /* GraphQL */ `
  query GetChatDatas($id: ID!) {
    getChatDatas(id: $id) {
      id
      chatMessage
      chatDateTime
      chatTimestamp
      checkOn
      chatroomID
      SendFromID
      chatOnImage
      chatDataStatus
      traineeCheckStatus
      coachCheckStatus
      csCheckStatus
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatDatas = /* GraphQL */ `
  query ListChatDatas(
    $filter: ModelChatDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatMessage
        chatDateTime
        chatTimestamp
        checkOn
        chatroomID
        SendFromID
        chatOnImage
        chatDataStatus
        traineeCheckStatus
        coachCheckStatus
        csCheckStatus
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectIsChatroomMember = /* GraphQL */ `
  query GetConectIsChatroomMember($id: ID!) {
    getConectIsChatroomMember(id: $id) {
      id
      chatroomID
      chatroomMemberUserDatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConectIsChatroomMembers = /* GraphQL */ `
  query ListConectIsChatroomMembers(
    $filter: ModelConectIsChatroomMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConectIsChatroomMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatroomID
        chatroomMemberUserDatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatrooms = /* GraphQL */ `
  query GetChatrooms($id: ID!) {
    getChatrooms(id: $id) {
      id
      chatroomUrl
      roomMasterUserDatasID
      ConectIsChatroomMembers {
        items {
          id
          chatroomID
          chatroomMemberUserDatasID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ChatDatas {
        items {
          id
          chatMessage
          chatDateTime
          chatTimestamp
          checkOn
          chatroomID
          SendFromID
          chatOnImage
          chatDataStatus
          traineeCheckStatus
          coachCheckStatus
          csCheckStatus
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
            __typename
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lastMessage
      unReadFlg
      roomMasterName
      sendFromTimestamp
      sendToTimestamp
      sendFromDateTime
      sendToDateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChatrooms = /* GraphQL */ `
  query ListChatrooms(
    $filter: ModelChatroomsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatrooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatroomUrl
        roomMasterUserDatasID
        ConectIsChatroomMembers {
          items {
            id
            chatroomID
            chatroomMemberUserDatasID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ChatDatas {
          items {
            id
            chatMessage
            chatDateTime
            chatTimestamp
            checkOn
            chatroomID
            SendFromID
            chatOnImage
            chatDataStatus
            traineeCheckStatus
            coachCheckStatus
            csCheckStatus
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        lastMessage
        unReadFlg
        roomMasterName
        sendFromTimestamp
        sendToTimestamp
        sendFromDateTime
        sendToDateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConectIsReadUnreadToNotifications = /* GraphQL */ `
  query GetConectIsReadUnreadToNotifications($id: ID!) {
    getConectIsReadUnreadToNotifications(id: $id) {
      id
      userdatasID
      notificationsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConectIsReadUnreadToNotifications = /* GraphQL */ `
  query ListConectIsReadUnreadToNotifications(
    $filter: ModelConectIsReadUnreadToNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConectIsReadUnreadToNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userdatasID
        notificationsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      notificationTitle
      notificationDateTime
      notificationTimestamp
      body
      ReadUnreadToNotifications {
        items {
          id
          userdatasID
          notificationsID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationTitle
        notificationDateTime
        notificationTimestamp
        body
        ReadUnreadToNotifications {
          items {
            id
            userdatasID
            notificationsID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTaskDatas = /* GraphQL */ `
  query GetTaskDatas($id: ID!) {
    getTaskDatas(id: $id) {
      id
      planTaskCreated
      planTaskStartTime
      planTaskEndTime
      planTaskPriority
      planTaskDetial
      resultTaskStartTime
      resultTaskEndTime
      resultTaskPriority
      resultTaskDetial
      tasktimeManagementTimestamp
      taskProgress
      parentTasktimeManagementDatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTaskDatas = /* GraphQL */ `
  query ListTaskDatas(
    $filter: ModelTaskDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        planTaskCreated
        planTaskStartTime
        planTaskEndTime
        planTaskPriority
        planTaskDetial
        resultTaskStartTime
        resultTaskEndTime
        resultTaskPriority
        resultTaskDetial
        tasktimeManagementTimestamp
        taskProgress
        parentTasktimeManagementDatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTasktimeManagementDatas = /* GraphQL */ `
  query GetTasktimeManagementDatas($id: ID!) {
    getTasktimeManagementDatas(id: $id) {
      id
      checkIn
      checkOut
      settingDate
      settingTimestamp
      tasktimeManagementOutput
      achievementScore
      userDatasID
      TaskDatas {
        items {
          id
          planTaskCreated
          planTaskStartTime
          planTaskEndTime
          planTaskPriority
          planTaskDetial
          resultTaskStartTime
          resultTaskEndTime
          resultTaskPriority
          resultTaskDetial
          tasktimeManagementTimestamp
          taskProgress
          parentTasktimeManagementDatasID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ttmActionToImprove
      ttmEffortTargets
      ttmIssuesToImprove
      ttmReflectionOnDay
      ttmStatus
      ttmPlanFeedbackMessage
      ttmResultFeedbackMessage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasktimeManagementDatas = /* GraphQL */ `
  query ListTasktimeManagementDatas(
    $filter: ModelTasktimeManagementDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasktimeManagementDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkIn
        checkOut
        settingDate
        settingTimestamp
        tasktimeManagementOutput
        achievementScore
        userDatasID
        TaskDatas {
          items {
            id
            planTaskCreated
            planTaskStartTime
            planTaskEndTime
            planTaskPriority
            planTaskDetial
            resultTaskStartTime
            resultTaskEndTime
            resultTaskPriority
            resultTaskDetial
            tasktimeManagementTimestamp
            taskProgress
            parentTasktimeManagementDatasID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        ttmActionToImprove
        ttmEffortTargets
        ttmIssuesToImprove
        ttmReflectionOnDay
        ttmStatus
        ttmPlanFeedbackMessage
        ttmResultFeedbackMessage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDetialAnswerHistories = /* GraphQL */ `
  query GetDetialAnswerHistories($id: ID!) {
    getDetialAnswerHistories(id: $id) {
      id
      answer
      comment
      answerDateTime
      commentDateTime
      answerTimestamp
      commentTimestamp
      parentEventDetialDatasID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDetialAnswerHistories = /* GraphQL */ `
  query ListDetialAnswerHistories(
    $filter: ModelDetialAnswerHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDetialAnswerHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answer
        comment
        answerDateTime
        commentDateTime
        answerTimestamp
        commentTimestamp
        parentEventDetialDatasID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventDetialDatas = /* GraphQL */ `
  query GetEventDetialDatas($id: ID!) {
    getEventDetialDatas(id: $id) {
      id
      parentScheduleEventsID
      caption
      answer
      DetialAnswerHistories {
        items {
          id
          answer
          comment
          answerDateTime
          commentDateTime
          answerTimestamp
          commentTimestamp
          parentEventDetialDatasID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventDetialDatas = /* GraphQL */ `
  query ListEventDetialDatas(
    $filter: ModelEventDetialDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventDetialDatas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentScheduleEventsID
        caption
        answer
        DetialAnswerHistories {
          items {
            id
            answer
            comment
            answerDateTime
            commentDateTime
            answerTimestamp
            commentTimestamp
            parentEventDetialDatasID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScheduleEvents = /* GraphQL */ `
  query GetScheduleEvents($id: ID!) {
    getScheduleEvents(id: $id) {
      id
      eventName
      eventType
      status
      deadlineDateTime
      deadlineTimestamp
      formType
      eventColorcode
      EventDetialDatas {
        items {
          id
          parentScheduleEventsID
          caption
          answer
          DetialAnswerHistories {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userDatasID
      corporationsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScheduleEvents = /* GraphQL */ `
  query ListScheduleEvents(
    $filter: ModelScheduleEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        eventType
        status
        deadlineDateTime
        deadlineTimestamp
        formType
        eventColorcode
        EventDetialDatas {
          items {
            id
            parentScheduleEventsID
            caption
            answer
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        userDatasID
        corporationsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCorporations = /* GraphQL */ `
  query GetCorporations($id: ID!) {
    getCorporations(id: $id) {
      id
      corporationName
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCorporations = /* GraphQL */ `
  query ListCorporations(
    $filter: ModelCorporationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCorporations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        corporationName
        TraineeDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        CoachDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ScheduleEvents {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        OfferDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        RafusalOfferDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRoleDatas = /* GraphQL */ `
  query GetRoleDatas($id: ID!) {
    getRoleDatas(id: $id) {
      id
      roleNumber
      roleName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoleDatas = /* GraphQL */ `
  query ListRoleDatas(
    $filter: ModelRoleDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoleDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleNumber
        roleName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserDatas = /* GraphQL */ `
  query GetUserDatas($id: ID!) {
    getUserDatas(id: $id) {
      id
      userId
      iconImage
      userRole
      TasktimeManagementDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ScheduleEvents {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ReadUnreadToNotifications {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ChatroomMaster {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ChatroomMember {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      TraineeDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      CoachDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      userName
      firstName
      middleName
      lastName
      firstNameKana
      middleNameKana
      lastNameKana
      universityName
      departmentName
      courseName
      AbuildSheetsDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      WeeklySurveyDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ELearningMovieDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      ChatDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      OfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      RafusalOfferDatas {
        items {
          id
          userName
          corporationName
          userdatasID
          corporationsID
          ScheduleEvents {
            id
            eventName
            eventType
            status
            deadlineDateTime
            deadlineTimestamp
            formType
            eventColorcode
            userDatasID
            corporationsID
            createdAt
            updatedAt
            __typename
          }
          refusalOfferStatus
          createdAt
          updatedAt
          refusalOfferDatasScheduleEventsId
          __typename
        }
        nextToken
        __typename
      }
      graduationYear
      graduateSchoolName
      graduateSchoolDepartmentName
      graduateSchoolCourseName
      graduateSchoolGraduationYear
      highSchoolName
      displayName
      userRemarks
      CustomerChatdatas {
        items {
          id
          chatMessage
          chatDateTime
          chatDateTimestamp
          checkOn
          customerchatroomsID
          sendUserdatasID
          chatOnImage
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CustomerChatrooms {
        items {
          id
          tag
          roomType
          CustomerChatdatas {
            nextToken
            __typename
          }
          userdatasID
          chatroomsID
          userdatasType
          roomMasterName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      CSMemberDatas {
        items {
          id
          userdatasID
          userName
          csChatroomType
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserDatas = /* GraphQL */ `
  query ListUserDatas(
    $filter: ModelUserDatasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        iconImage
        userRole
        TasktimeManagementDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ScheduleEvents {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ReadUnreadToNotifications {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ChatroomMaster {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ChatroomMember {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        TraineeDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        CoachDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        userName
        firstName
        middleName
        lastName
        firstNameKana
        middleNameKana
        lastNameKana
        universityName
        departmentName
        courseName
        AbuildSheetsDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        WeeklySurveyDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ELearningMovieDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        ChatDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        OfferDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        RafusalOfferDatas {
          items {
            id
            userName
            corporationName
            userdatasID
            corporationsID
            refusalOfferStatus
            createdAt
            updatedAt
            refusalOfferDatasScheduleEventsId
            __typename
          }
          nextToken
          __typename
        }
        graduationYear
        graduateSchoolName
        graduateSchoolDepartmentName
        graduateSchoolCourseName
        graduateSchoolGraduationYear
        highSchoolName
        displayName
        userRemarks
        CustomerChatdatas {
          items {
            id
            chatMessage
            chatDateTime
            chatDateTimestamp
            checkOn
            customerchatroomsID
            sendUserdatasID
            chatOnImage
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CustomerChatrooms {
          items {
            id
            tag
            roomType
            userdatasID
            chatroomsID
            userdatasType
            roomMasterName
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        CSMemberDatas {
          items {
            id
            userdatasID
            userName
            csChatroomType
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTraineeRules = /* GraphQL */ `
  query GetTraineeRules($id: ID!) {
    getTraineeRules(id: $id) {
      id
      number
      caption
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTraineeRules = /* GraphQL */ `
  query ListTraineeRules(
    $filter: ModelTraineeRulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraineeRules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        caption
        body
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
