/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "9c0fa05943a540d9a0038d9d6f82dad1",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9c0fa05943a540d9a0038d9d6f82dad1",
            "region": "ap-northeast-1"
        }
    },
    "Notifications": {
        "InAppMessaging": {
            "AWSPinpoint": {
                "appId": "9c0fa05943a540d9a0038d9d6f82dad1",
                "region": "ap-northeast-1"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://7iap2eanuf.execute-api.ap-northeast-1.amazonaws.com/dev",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2fc4ifnmvnhpnk4ltex3six54i.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:a07c2f0e-d822-48ef-9c2b-1c4333d6bbd1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_brlGukjGX",
    "aws_user_pools_web_client_id": "7oh5gspvm3unj80sqctkcjt1rb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tasktimemngapp-storage-67d2296b145154-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
