// AWS
import { API } from "aws-amplify";

// GraphQL
import { 
    updateTasktimeManagementDatas, 
} from '../../graphql/mutations';
import { 
    createTaskDatas, 
} from '../../graphql/mutations';
import { 
    onCreateTaskDatas, 
    onDeleteTaskDatas, 
    onUpdateTaskDatas, 
    onUpdateTasktimeManagementDatas, 
} from '../../graphql/subscriptions';

// React
import { 
    useEffect, 
    useState, 
    Fragment,
} from 'react';
import AddIcon from '@material-ui/icons/Add';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import { 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Select, 
    MenuItem, 
    TextField, 
    Fab, 
    InputLabel, 
    FormControl, 
    Tabs, 
    Tab,
    Button,
} from '@material-ui/core';

// Self Making Files
import { 
    AMAZON_COGNITO_USER_POOLS, 
    DIALOG_TTM_COMMIT_ERROR_CAPTION, 
    DIALOG_TTM_COMMIT_ERROR_CONTENT_TEXT, 
    ERROR_TYPE_ABORTERROR, 
    FORMAT_STRING_MMONTH_DDAY, 
    INITIAL_QUERY, 
    SUBSCRIPTION, 
    SUBSCRIPTION_DELETE, 
    SUBSCRIPTION_UPDATE, 
    TAB_MENU_TITLE_ACHIVEMENT, 
    TAB_MENU_TITLE_PLANS, 
    TASK_TITLE_ACTION_TO_IMPROVE, 
    TASK_TITLE_ISSUES_TO_IMPROVE, 
    TASK_TITLE_REFLECTION_ON_DAY, 
    TASK_TITLE_TASK_ACHIEVEMENT, 
    TASK_TITLE_TODAY_EFFORT_TARGETS, 
    TASK_TITLE_TODAY_REFLECTION, 
    TASK_TITLE_TODAY_TARGETS, 
    TASLTIMES_PROMPT_FILL_MESSAGE, 
    TTM_DATA_ACHIEVEMENTSCORE, 
    TTM_DATA_ACTIONTOIMPROVE, 
    TTM_DATA_EFFORTTARGETS, 
    TTM_DATA_ISSUESTOIMPROVE, 
    TTM_DATA_REFLECTIONONDAY, 
    TTM_DATA_TASKTIMEMANAGEMENTOUTPUT, 
    UPDATE, 
    USER_ROLE, 
} from '../../Commons/Consts';
import { 
    formatAWSDateStr, 
    getStringFromDateForFormatString, 
    nonZerosuppresFormatDate, 
    consoleLog, 
} from '../../Commons/CommonFunctions';

import { PlanTaskCardList } from './PlanTaskCardList';
import { ResultTaskCardList } from './ResultTaskCardList';
import { 
    a11yProps, 
    TabPanel 
} from "../../Commons/TabPanel";
import { commonStyles } from "../../Commons/CommonStyles";
import styled from 'styled-components';
import { common } from "@material-ui/core/colors";
import CommonDialog from "../../Commons/CommonDialog";

const localStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 100,
        backgroundColor: theme.palette.background.paper,
        "& .MuiInputBase-input":{
            [theme.breakpoints.down('sm')]: {
                fontSize: "3vmin",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "2vmin",
            }
        }
    },
    formPCControl: {
        minWidth: 100,
        backgroundColor: theme.palette.background.drawerPaper,
        "& .MuiInputBase-input":{
            [theme.breakpoints.down('sm')]: {
                fontSize: "3vmin",
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "1.5vmin",
            }
        },
        "& .MuiOutlinedInput-multiline":{
            padding: "3px",
        },
    },
    achievementSelect:{
        display: 'flex',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: "5px",
        "& .MuiSelect-root":{
            padding: "5px 5px 0px 5px",
        }
    },
    settingDateDisplay:{
        padding: "3px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    tabMenu:{
        minWidth: "100%",
        maxWidth: "100%",
        width: "100%",
        minHeight: "5%",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        "& .MuiTab-root":{
            height: "5%",
            minHeight: "5%",
        },
    },
    widthHerf:{
        minWidth: "50%",
        maxWidth: "50%",
        width: "50%",
        backgroundColor: theme.palette.background.paper,
    },
    divider:{
        border: "solid #0A0A0A 1px",
        marginTop: "5px",
        marginBottom: "5px", 
    },
    gridFromControl:{
        padding: "3px",
        borderRadius: "3px",
        marginBottom: "5px",
        backgroundColor: theme.palette.background.paper,
    },
    captionBox:{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
    },
    bcBrown:{
        backgroundColor: "#bcaaa4",
    },
    ttmArea:{
        borderTop: "solid 1px #ffffff",
        position: "fixed",
        width: "45%",
        bottom: 5,
    },
    fabbutArea:{
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
    },
    planTasksArea:{
        minHeight: "20vh",
        height: "40%",
        maxHeight: "40vh",
        overflowY: "scroll",
    },
    resultTasksArea:{
        height: "15vh",
        overflowY: "scroll",
    },
    fabbut:{
        width: "32px",
        height: "32px",
        zIndex: 1000,
    },
}));

const NoscrollDiv = styled.div`
    /* ~~~ container styles ~~~ */
    .MuiBox-root{
        /*要素の高さを指定*/
        /*上下方向にはみ出した要素ををスクロールさせる*/
        overflow-y: scroll;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }
    /*スクロールバー非表示（Chrome・Safari）*/
    .MuiBox-root::-webkit-scrollbar{
        display:none;
    }
`;


export default function PCTaskTimeManagementEditor({ tasks, dispatchTasks, ttm, dispatchTtm, userData, timestamp, ttmId }){
    const commonStyle = commonStyles();
    const localStyle = localStyles();
    const [value, setValue] = useState(0);
    const [checkingPlanFlag, setCheckingPlanFlag] = useState(false);
    const [checkingResultFlag, setCheckingReslutFlag] = useState(false);
    const [writeningCommitError, setWriteningCommitError] = useState(false);
    
    const initTaskTimeDetail = async (type) =>{
        try {
            // コーチが確認した時にCheckOutされる
            if(userData.userRole === USER_ROLE.Coach ||
                userData.userRole === USER_ROLE.CustomerSuccess){
                if(ttm.ttmStatus === 2){
                    setCheckingPlanFlag(true);
                }else if(ttm.ttmStatus === 5){
                    setCheckingReslutFlag(true);
                }
                //checkOutingProcess(tasks, ttm, type);
            }
        } catch (e) {
            if (e.name === ERROR_TYPE_ABORTERROR) {
                // something to do when error occur
                consoleLog(userData.userRole, {ABORTERROR:e});
            }
        }
    }

    const ttmPlanCommitButtonClick = async () =>{
        await ttmPlanCommitProcess(tasks, ttm, INITIAL_QUERY);
    }

    const ttmPlanCommitProcess = async (tasks, ttmDatas, type) =>{
        if(!tasks.some(someIsPlanStatus) &&
            checkIsPlanTtmStatus(ttmDatas)){
            const ttmUpdRes = await API.graphql({
                query: updateTasktimeManagementDatas,
                variables: { 
                    input: {
                        id:ttmDatas.id,
                        ttmStatus: 2,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchTtm({ type: type, data:ttmUpdRes.data.updateTasktimeManagementDatas });
            alert('確認依頼を提出');
        }
        else{
            setWriteningCommitError(true);
        }
    }

    const ttmResultCommitButtonClick = async () =>{
        await ttmResultCommitProcess(tasks, ttm, INITIAL_QUERY);
    }

    const ttmResultCommitProcess = async (tasks, ttmDatas, type) =>{
        if(!tasks.some(someIsPlanStatus) &&
            checkIsPlanTtmStatus(ttmDatas)){
            const ttmUpdRes = await API.graphql({
                query: updateTasktimeManagementDatas,
                variables: { 
                    input: {
                        id:ttmDatas.id,
                        ttmStatus: 5,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchTtm({ type: type, data:ttmUpdRes.data.updateTasktimeManagementDatas });
            alert('確認依頼を提出');
        }
        else{
            setWriteningCommitError(true);
        }
    }

    const someIsPlanStatus = (data) =>{
        if(data.planTaskCreated){
            return data.planTaskDetial === '';
        }
        else{
            return false;
        }
    }
    
    const checkIsPlanTtmStatus = (data) =>{
        return data.tasktimeManagementOutput !== '' && data.ttmEffortTargets !== '';
    }

    const ttmPlanCheckButtonClick = async () =>{
        await checkPlanCheckProcess(tasks, ttm, INITIAL_QUERY);
    }

    const checkPlanCheckProcess = async (tasks, ttmDatas, type) =>{
        if(!tasks.some(someIsReportingStatus) &&
            checkIsTtmStatus(ttmDatas)){
            const ttmUpdRes = await API.graphql({
                query: updateTasktimeManagementDatas,
                variables: { 
                    input: {
                        id:ttmDatas.id,
                        checkOut: true,
                        ttmStatus: 3,
                    },
                },
                authMode: AMAZON_COGNITO_USER_POOLS,
            });
            dispatchTtm({ type: type, data:ttmUpdRes.data.updateTasktimeManagementDatas });
            alert('確認完了');
        }
        else{
            setWriteningCommitError(true);
        }
    }

    const someIsReportingStatus = (data) =>{
        return data.resultTaskDetial === '' && data.taskProgress === 0
    }
    const checkIsTtmStatus = (data) =>{
        return data.ttmActionToImprove !== '' && data.ttmIssuesToImprove !== '' && data.ttmReflectionOnDay !== ''
    }
    
    const createPlaninTask = async (id) =>{
        var ttmId = null;
        if(id !== null){
            ttmId = id;
        }
        else{
            ttmId = ttm.id;
        }
        
        var newStartTime = "08:00";
        var newEndTime = "09:00";
        if(0 < tasks.length){
            const baseData = tasks.filter(data => { return data.planTaskCreated === true}).sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1}).at(-1);
            const endTimeValues = baseData.planTaskEndTime.split(':');
            if(23 <= endTimeValues[0]){
                endTimeValues[0] = '00'
            }
            else{
                endTimeValues[0] = ('0' + (Number(endTimeValues[0]) + 1).toString()).slice(-2);
            }
            newStartTime = baseData.planTaskEndTime;
            newEndTime = endTimeValues.join(':');
        }
        
        await API.graphql({
            query: createTaskDatas,
            variables: {
                input: {
                    parentTasktimeManagementDatasID: ttmId, 
                    planTaskDetial: "", 
                    planTaskCreated: true, 
                    planTaskStartTime: newStartTime, 
                    planTaskEndTime: newEndTime, 
                    planTaskPriority: 0, 
                    resultTaskDetial: "", 
                    resultTaskStartTime: newStartTime, 
                    resultTaskEndTime: newEndTime, 
                    resultTaskPriority: 0, 
                    taskProgress: 0, 
                    tasktimeManagementTimestamp: new Date(formatAWSDateStr(timestamp)).valueOf()
                }    
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttmId,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    };
    
    const createNoPlanTask = async (id) =>{
        var ttmId = null;
        if(id !== null){
            ttmId = id;
        }
        else{
            ttmId = ttm.id;
        }
        
        var newStartTime = "08:00";
        var newEndTime = "09:00";
        if(0 < tasks.length){
            const baseData = tasks.sort((a, b) => {return a.createdAt < b.createdAt ? -1 : 1}).at(-1);
            const endTimeValues = baseData.planTaskEndTime.split(':');
            if(23 <= endTimeValues[0]){
                endTimeValues[0] = '00'
            }
            else{
                endTimeValues[0] = ('0' + (Number(endTimeValues[0]) + 1).toString()).slice(-2);
            }
            newStartTime = baseData.planTaskEndTime;
            newEndTime = endTimeValues.join(':');
        }
        
        await API.graphql({
            query: createTaskDatas,
            variables: {
                input: {
                    parentTasktimeManagementDatasID: ttmId, 
                    planTaskDetial: "", 
                    planTaskCreated: false, 
                    planTaskStartTime: newStartTime, 
                    planTaskEndTime: newEndTime, 
                    planTaskPriority: 0, 
                    resultTaskDetial: "", 
                    resultTaskStartTime: newStartTime, 
                    resultTaskEndTime: newEndTime, 
                    resultTaskPriority: 0, 
                    taskProgress: 0, 
                    tasktimeManagementTimestamp: new Date(formatAWSDateStr(timestamp)).valueOf()
                }    
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttmId,
                    checkIn: true,
                    checkOut: false,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
    };
    
    const updateTtmDatas = async (type) => {
        // 作成時のステータスから更新するタイミングは0から1にする
        var updStatus = ttm.ttmStatus;
        if(ttm.ttmStatus === 0){
            updStatus = 1;
        }
        const res = await API.graphql({
            query: updateTasktimeManagementDatas,
            variables: { 
                input: {
                    id:ttm.id,
                    achievementScore: ttm.achievementScore,
                    checkIn: true,
                    checkOut: false,
                    tasktimeManagementOutput: ttm.tasktimeManagementOutput,
                    ttmActionToImprove: ttm.ttmActionToImprove,
                    ttmEffortTargets: ttm.ttmEffortTargets, 
                    ttmIssuesToImprove: ttm.ttmIssuesToImprove, 
                    ttmReflectionOnDay: ttm.ttmReflectionOnDay,
                    ttmStatus: updStatus,
                },
            },
            authMode: AMAZON_COGNITO_USER_POOLS,
        });
        dispatchTtm({ type: INITIAL_QUERY, data:res.data.updateTasktimeManagementDatas });
    }

    const bulrTtmUpdate = () =>{
        updateTtmDatas();
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        // マウント時処理
        // 初期化処理
        let abortCtrl = new AbortController();
        initTaskTimeDetail(INITIAL_QUERY);

        // APIサブスクリプション登録
        const subscription = API.graphql({
            query:onUpdateTaskDatas,
            authMode: AMAZON_COGNITO_USER_POOLS // 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const task = msg.value.data.onUpdateTaskDatas;
                dispatchTasks({ type: SUBSCRIPTION_UPDATE, data: task });
            }
        });
        const createSubscription = API.graphql({
            query:onCreateTaskDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const task = msg.value.data.onCreateTaskDatas;
                if(ttmId === task.parentTasktimeManagementDatasID){
                    dispatchTasks({ type: SUBSCRIPTION, data: task });
                }
            }
        });
        const updateTtmSubscription = API.graphql({
            query:onUpdateTasktimeManagementDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const updateTtm = msg.value.data.onUpdateTasktimeManagementDatas;
                if(ttmId === updateTtm.id){
                    dispatchTtm({ type: INITIAL_QUERY, data: updateTtm });
                }
            }
        });
        const deleteSubscription = API.graphql({
            query:onDeleteTaskDatas,
            authMode: AMAZON_COGNITO_USER_POOLS// 複数の認証モードを利用している場合は、この指定が必要
        }).subscribe({
            next: (msg) => {
                const task = msg.value.data.onDeleteTaskDatas;
                if(ttmId === task.parentTasktimeManagementDatasID){
                    dispatchTasks({ type: SUBSCRIPTION_DELETE, data: task });
                }
            }
        });
        
        // アンマウント時処理
        return () => {
            abortCtrl.abort();
            createSubscription.unsubscribe();
            subscription.unsubscribe();
            updateTtmSubscription.unsubscribe();
            deleteSubscription.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Box>
            <Box className={localStyle.settingDateDisplay}>
                {ttm.settingDate !== undefined ? 
                <Typography variant="h6">
                    {nonZerosuppresFormatDate(new Date(ttm.settingDate), FORMAT_STRING_MMONTH_DDAY)}
                </Typography>
                :
                <Fragment></Fragment>
                }
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className={localStyle.tabMenu} aria-label="basic tabs example">
                <Tab label={TAB_MENU_TITLE_PLANS} {...a11yProps(0)} className={localStyle.widthHerf} />
                <Tab label={TAB_MENU_TITLE_ACHIVEMENT} {...a11yProps(1)} className={localStyle.widthHerf} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box pt={1}>
                    <NoscrollDiv>
                        <Box className={localStyle.planTasksArea}>
                            <PlanTaskCardList 
                                tasks={tasks} 
                                userRole={userData.userRole}
                            />
                        </Box>
                    </NoscrollDiv>
                    {userData.userRole === USER_ROLE.Trainee ?
                    <Box className={localStyle.fabbutArea}>
                        <Fab 
                            color="primary" 
                            aria-label="add" 
                            className={localStyle.fabbut} 
                            onClick={(event) => {createPlaninTask(ttm.id)}}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>
                    :
                    <Fragment></Fragment>
                    }
                    <Box pt={1} className={localStyle.ttmArea}>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={3}
                                    maxRows={3}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.tasktimeManagementOutput}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_TASKTIMEMANAGEMENTOUTPUT, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_EFFORT_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    minRows={3}
                                    maxRows={3}
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.ttmEffortTargets}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_EFFORTTARGETS, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                        </Grid>
                        {ttm.ttmPlunFeedbackMessage !== undefined?
                        ttm.ttmPlunFeedbackMessage !== ''?
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">確認者フィードバック</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled
                                    className={localStyle.formPCControl}
                                    value={ttm.ttmPlunFeedbackMessage}
                                />
                            </Grid>
                        </Grid>
                        :<Fragment></Fragment>
                        :<Fragment></Fragment>
                        }
                        {checkingPlanFlag === false ?
                        <Grid container className={commonStyle.centerOfCenterContents}>
                            <Button 
                                onClick={ttmPlanCommitButtonClick} 
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                予定確認依頼
                            </Button>
                        </Grid>
                        :
                        <Grid container className={commonStyle.centerOfCenterContents}>
                            <Button 
                                onClick={ttmPlanCheckButtonClick} 
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                予定確認完了
                            </Button>
                        </Grid>
                        }
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box pt={1}>
                    <NoscrollDiv>
                        <Box className={localStyle.resultTasksArea}>
                            <ResultTaskCardList 
                                tasks={tasks} 
                                userRole={userData.userRole}
                            />
                        </Box>
                    </NoscrollDiv>
                    {userData.userRole === USER_ROLE.Trainee ?
                    <Box className={localStyle.fabbutArea}>
                        <Fab 
                            color="primary" 
                            aria-label="add" 
                            className={localStyle.fabbut} 
                            onClick={(event) => {createNoPlanTask(ttm.id)}}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>
                    :
                    <Fragment></Fragment>
                    }
                    <Box pt={1} className={localStyle.ttmArea}>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.tasktimeManagementOutput}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_TASKTIMEMANAGEMENTOUTPUT, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={localStyle.gridFromControl}>
                            <Grid item xs={3}>
                                <Box className={localStyle.captionBox}>
                                    <Typography variant="caption">{TASK_TITLE_TODAY_EFFORT_TARGETS}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    disabled={userData.userRole === 17 ? false : true}
                                    InputLabelProps={{ shrink: true }}
                                    className={localStyle.formPCControl}
                                    value={ttm.ttmEffortTargets}
                                    placeholder={TASLTIMES_PROMPT_FILL_MESSAGE}
                                    onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_EFFORTTARGETS, updateValue:event.target.value })}}
                                    onBlur={bulrTtmUpdate}
                                />
                            </Grid>
                        </Grid>
                        <Box className={localStyle.divider} />
                        <Grid container>    
                            <Grid item xs={6} className={commonStyle.rightContents}>
                                <Typography variant='h6'>{TASK_TITLE_TODAY_REFLECTION}</Typography>
                            </Grid>
                            <Grid container xs={6} className={localStyle.achievementSelect}>
                                <FormControl variant="outlined" className={localStyle.formControl}>
                                    <Select
                                        labelId="task-achievement-rate-select-label"
                                        id="task-achievement-rate-select"
                                        disabled={userData.userRole === 17 ? false : true}
                                        value={ttm.length <= 0 ? 
                                            0    
                                            :
                                            ttm.achievementScore
                                            }
                                        onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ACHIEVEMENTSCORE, updateValue:event.target.value })}}
                                        onBlur={bulrTtmUpdate}
                                    >
                                        <MenuItem value={0}>達成率</MenuItem>
                                        <MenuItem value={5}>5%</MenuItem>
                                        <MenuItem value={10}>10%</MenuItem>
                                        <MenuItem value={15}>15%</MenuItem>
                                        <MenuItem value={20}>20%</MenuItem>
                                        <MenuItem value={25}>25%</MenuItem>
                                        <MenuItem value={30}>30%</MenuItem>
                                        <MenuItem value={35}>35%</MenuItem>
                                        <MenuItem value={40}>40%</MenuItem>
                                        <MenuItem value={45}>45%</MenuItem>
                                        <MenuItem value={50}>50%</MenuItem>
                                        <MenuItem value={55}>55%</MenuItem>
                                        <MenuItem value={60}>60%</MenuItem>
                                        <MenuItem value={65}>65%</MenuItem>
                                        <MenuItem value={70}>70%</MenuItem>
                                        <MenuItem value={75}>75%</MenuItem>
                                        <MenuItem value={80}>80%</MenuItem>
                                        <MenuItem value={85}>85%</MenuItem>
                                        <MenuItem value={90}>90%</MenuItem>
                                        <MenuItem value={95}>95%</MenuItem>
                                        <MenuItem value={100}>100%</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_REFLECTION_ON_DAY}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmReflectionOnDay}
                                        onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_REFLECTIONONDAY, updateValue:event.target.value })}}
                                        onBlur={bulrTtmUpdate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_ISSUES_TO_IMPROVE}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmIssuesToImprove}
                                        onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ISSUESTOIMPROVE,updateValue:event.target.value })}}
                                        onBlur={bulrTtmUpdate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">{TASK_TITLE_ACTION_TO_IMPROVE}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled={userData.userRole === 17 ? false : true}
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmActionToImprove}
                                        onChange={(event) => {dispatchTtm({type: UPDATE, updateName:TTM_DATA_ACTIONTOIMPROVE, updateValue:event.target.value })}}
                                        onBlur={bulrTtmUpdate}
                                    />
                                </Grid>
                            </Grid>
                            {ttm.ttmResultFeedbackMessage !== undefined?
                            ttm.ttmResultFeedbackMessage !== ''?
                            <Grid container className={localStyle.gridFromControl}>
                                <Grid item xs={3}>
                                    <Box className={localStyle.captionBox}>
                                        <Typography variant="caption">確認者フィードバック</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        disabled
                                        className={localStyle.formPCControl}
                                        value={ttm.ttmResultFeedbackMessage}
                                    />
                                </Grid>
                            </Grid>
                            :<Fragment></Fragment>
                            :<Fragment></Fragment>
                            }
                            {checkingPlanFlag === false ?
                            <Grid container className={commonStyle.centerOfCenterContents}>
                                <Button 
                                    onClick={ttmResultCommitButtonClick} 
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    実績確認依頼
                                </Button>
                            </Grid>
                            :
                            <Grid container className={commonStyle.centerOfCenterContents}>
                                <Button 
                                    onClick={ttmResultCommitButtonClick} 
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    実績確認完了
                                </Button>
                            </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            </TabPanel>
            <CommonDialog
                open={writeningCommitError}
                setOpen={setWriteningCommitError}
                caption={DIALOG_TTM_COMMIT_ERROR_CAPTION}
                contentText={DIALOG_TTM_COMMIT_ERROR_CONTENT_TEXT}
            />
        </Box>
    );
}